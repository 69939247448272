import React from 'react';
import { formatDate } from 'utils/formatDate';
import { immutableSort } from 'utils/immutableSort';
import './changesHistory.css';
var statusDictionary = {
    SUBMITTED: 'Wysłany',
    APPROVED: 'Zaakceptowany',
    DECLINED: 'Odrzucony',
    ERROR: 'Błąd',
    TENTATIVELY_ACCEPTED: 'Wstępnie zaakceptowany',
    SUBMITTED_RECKONING: 'Zamówienie do rozliczenia',
    APPROVED_RECKONING: 'Zamówienie rozliczone',
    DECLINED_RECKONING: 'Zamówienie odrzucone',
    SUBMITTED_CONDITION: 'Zmiana warunków',
    APPROVED_CONDITION: 'Zmiana warunków zaakceptowana',
    DECLINED_CONDITION: 'Zmiana warunków odrzucona',
    APPROVED_DURATION_EXTENSION: 'Zmiana daty ważności zaakceptowana',
    DECLINED_DURATION_EXTENSION: 'Zmiana daty ważności odrzucona',
    SUBMITTED_DURATION_EXTENSION: 'Zmiana daty ważności',
    BUSINESS_DURATION_EXTENSION: 'Zmiana daty ważności VCP',
    WITHDRAWN_RECKONING: 'Zamówienie wycofane',
    RECKONING_CORRECTED: 'Rozliczenie skorygowane',
    ADJUSTED: 'Rozliczenie skorygowane'
};
export function ChangesHistory(_a) {
    var changesHistory = _a.changesHistory;
    var historyData = changesHistory;
    var sortedHistoryData = immutableSort(historyData, function (a, b) {
        var dateA = new Date(a.eventDate).getTime();
        var dateB = new Date(b.eventDate).getTime();
        return dateA - dateB;
    });
    function getDate(timestamp) {
        var date = timestamp.split(' ')[0];
        var formattedDate = formatDate(new Date(date));
        return formattedDate;
    }
    function getTime(timestamp) {
        var time = timestamp.split(' ')[1];
        var _a = time.split(':'), hour = _a[0], minutes = _a[1], seconds = _a[2];
        var formattedTime = hour + ':' + minutes;
        return formattedTime;
    }
    return React.createElement("div", { className: 'tradeInChangesHistoryWrapper' },
        React.createElement("div", { className: 'tradeInChangesHistoryHeader' }, "historia zmian"),
        React.createElement("div", { className: 'tradeInchangesHistoryTableWrapper', "data-testid": 'trade-in-changes-history-table-wrapper' },
            React.createElement("table", { className: 'tradeInChangesHistoryTable' },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: 'tradeInChangesHistoryTableHeadCell tradeInChangesHistoryTableDateCell' }, "Data i godzina"),
                        React.createElement("th", { className: 'tradeInChangesHistoryTableHeadCell tradeInChangesHistoryTableStatusCell', style: { paddingRight: '12px' } }, "Status"),
                        React.createElement("th", { className: 'tradeInChangesHistoryTableHeadCell tradeInChangesHistoryTableCommentCell' }, "Komentarz"),
                        React.createElement("th", { className: 'tradeInChangesHistoryTableHeadCell tradeInChangesHistoryTableAuthorCell' }, "Wprowadzaj\u0105cy"))),
                React.createElement("tbody", null, sortedHistoryData.map(function (entry) { return (React.createElement("tr", { className: 'tradeInChangesHistoryTableRow', key: entry.id },
                    React.createElement("td", { className: 'tradeInChangesHistoryTableBodyCell tradeInChangesHistoryTableDateCell' },
                        React.createElement("div", { className: 'tradeInChangesHistoryDateAndTimeWrapper' }, entry.eventDate ?
                            React.createElement(React.Fragment, null,
                                React.createElement("div", null, getDate(entry.eventDate)),
                                React.createElement("div", null, getTime(entry.eventDate)))
                            : null)),
                    React.createElement("td", { className: 'tradeInChangesHistoryTableBodyCell tradeInChangesHistoryTableStatusCell' }, statusDictionary[entry.status]),
                    React.createElement("td", { className: 'tradeInChangesHistoryTableBodyCell tradeInChangesHistoryTableCommentCell' }, entry.additionalInfo),
                    React.createElement("td", { className: 'tradeInChangesHistoryTableBodyCell tradeInChangesHistoryTableAuthorCell' }, entry.applicantId))); })))));
}
