// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .orderingPartySectionWrapper {
    padding-inline: 16px;
    padding-block: 24px;
    width: 100%;
    font-family: var(--font-family);
    color: var(--main-text-color);
}

#new-dol .orderingPartySectionDropdownsContainer {
    width: 782px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 16px;
}

#new-dol .orderingPartyLabel {
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--black-text-color);
    margin-bottom: 8px;
}

#new-dol .orderingPartySectionValue {
    color: var(--black-text-color);
    padding-left: 16px;
    font-size: 14px;
    line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tradeIn/orderingPartySection/orderingPartySection.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;IACX,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".orderingPartySectionWrapper {\n    padding-inline: 16px;\n    padding-block: 24px;\n    width: 100%;\n    font-family: var(--font-family);\n    color: var(--main-text-color);\n}\n\n.orderingPartySectionDropdownsContainer {\n    width: 782px;\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n    margin-bottom: 16px;\n}\n\n.orderingPartyLabel {\n    padding-left: 16px;\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 500;\n    color: var(--black-text-color);\n    margin-bottom: 8px;\n}\n\n.orderingPartySectionValue {\n    color: var(--black-text-color);\n    padding-left: 16px;\n    font-size: 14px;\n    line-height: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
