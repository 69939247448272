import { withCancellation } from 'common/requestCancellation/requestCancellation';
import React, { useEffect } from 'react';
import './newOfferFromStock.css';
import { NewConfigurationSection } from './sections/NewConfiguration/NewConfigurationSection.component';
import { SearchCarSection } from './sections/SearchCar/SearchCarSection.component';
import { useStatus } from 'common/statusContext';
import { Spinner } from 'components/spinner/Spinner';
import { useAppError } from 'common/appErrorContext';
import { ErrorPopupWithRetry } from 'pages/creator/popup/ErrorPopupWithRetry';
export var NewOfferFromStock = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useAppError(), appError = _b.appError, setAppError = _b.setAppError;
    function isErrorPopup() {
        return (appError === null || appError === void 0 ? void 0 : appError.redirect) === 'errorPopup';
    }
    useEffect(function () {
        return function () {
            setAppError(undefined);
        };
    }, []);
    function handleSetAppError(newAppError) {
        setAppError(newAppError);
    }
    return (React.createElement(React.Fragment, null,
        status === 'pending' &&
            React.createElement(Spinner, null),
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'newOfferFromStockContainer' },
                React.createElement(NewConfigurationSection, null),
                React.createElement(SearchCarSection, { handleSetAppError: handleSetAppError })),
            isErrorPopup() ? React.createElement(ErrorPopupWithRetry, { text: appError.errorDesc }) : null)));
});
