// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .numberInputContainer{
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    font-family: var(--font-family);
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border: 1px solid #D5D5D5;
    border-radius: 4px;
    padding: 6px 16px;
    background-color: #FFFFFF;
}

#new-dol .numberInputContainer input::placeholder {
    color: var(--text-grey);
    font-family: var(--font-family);
    text-align: left;
}

#new-dol .numberInputContainer input {
    border: none;
    box-sizing: border-box;
    width: 100%;
}

#new-dol .numberInputContainer input:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/numberInput/numberInput.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,+BAA+B;IAC/B,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,+BAA+B;IAC/B,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".numberInputContainer{\n    width: 100%;\n    height: 32px;\n    display: flex;\n    align-items: center;\n    font-family: var(--font-family);\n    font-size: 14px;\n    line-height: 20px;\n    font-weight: 400;\n    border: 1px solid #D5D5D5;\n    border-radius: 4px;\n    padding: 6px 16px;\n    background-color: #FFFFFF;\n}\n\n.numberInputContainer input::placeholder {\n    color: var(--text-grey);\n    font-family: var(--font-family);\n    text-align: left;\n}\n\n.numberInputContainer input {\n    border: none;\n    box-sizing: border-box;\n    width: 100%;\n}\n\n.numberInputContainer input:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
