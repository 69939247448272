var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useOrganizations } from 'automatic-sale-funnel/organizations';
import { formatDate } from 'common/formatters/date';
import { partyDescription } from 'common/party';
import { useCarDefinitions } from 'extra-sale-discounts/carDefinitions';
import { useCars } from 'extra-sale-discounts/cars';
import { useClientParties } from 'extra-sale-discounts/clientParties';
import { useConcessions } from 'extra-sale-discounts/concessions';
import { useExtraSaleDiscounts } from 'extra-sale-discounts/extraSaleDiscounts';
import { useUserCards } from 'extra-sale-discounts/userCards';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
export function useSubmissionForm(application) {
    var organizations = useOrganizations();
    var userCards = useUserCards();
    var clientParties = useClientParties();
    var carDefinitions = useCarDefinitions();
    var extraSaleDiscounts = useExtraSaleDiscounts();
    var concessions = useConcessions();
    var cars = useCars();
    var _a = useState({
        applicantId: undefined,
        funding: undefined,
        applicationReasons: undefined,
        party: undefined
    }), applicationDraft = _a[0], setApplicationDraft = _a[1];
    var _b = useState(), selectedPartyId = _b[0], setSelectedPartyId = _b[1];
    var _c = useState(), selectedParty = _c[0], setSelectedParty = _c[1];
    var _d = useState([]), partyCars = _d[0], setPartyCars = _d[1];
    var _e = useState([]), availableParties = _e[0], setAvailableParties = _e[1];
    var _f = useState({
        name: undefined,
        email: undefined,
        phoneNumber: undefined,
        organizationName: undefined
    }), applicant = _f[0], setApplicant = _f[1];
    var _g = useState(0), carNetListPrice = _g[0], setCarNetListPrice = _g[1];
    var _h = useState(0), carGrossListPrice = _h[0], setCarGrossListPrice = _h[1];
    var _j = useState(0), carsSalePotential = _j[0], setCarsSalePotential = _j[1];
    var _k = useState(''), comment = _k[0], setComment = _k[1];
    var _l = useState({}), funding = _l[0], setFunding = _l[1];
    var _m = useState(moment(Date.now())), plannedHandoverDate = _m[0], setPlannedHandoverDate = _m[1];
    var _o = useState({}), carDefinition = _o[0], setCarDefinition = _o[1];
    var _p = useState(null), settlement = _p[0], setSettlement = _p[1];
    var _q = useState(false), loading = _q[0], setLoading = _q[1];
    var _r = useState(false), loadingPartyExtraSaleDiscount = _r[0], setLoadingPartyExtraSaleDiscount = _r[1];
    var _s = useState([]), concessionsOfParty = _s[0], setConcessionsOfParty = _s[1];
    var _t = useState([]), partyExtraSaleDiscounts = _t[0], setPartyExtraSaleDiscounts = _t[1];
    var _u = useState([]), availableApplicationReasons = _u[0], setAvailableApplicationReasons = _u[1];
    var _v = useState([]), attachments = _v[0], setAttachments = _v[1];
    useEffect(function () {
        if (!application) {
            return;
        }
        Promise.resolve(application)
            .then(function (data) {
            var _a, _b;
            setApplicationDraft(data);
            setSelectedPartyId(data.party.id);
            setCarsSalePotential(data.carsSalePotential || 0);
            setCarNetListPrice(((_a = data === null || data === void 0 ? void 0 : data.car) === null || _a === void 0 ? void 0 : _a.netListPrice) || 0);
            setCarDefinition((data === null || data === void 0 ? void 0 : data.car) || {});
            setComment(data.comment || '');
            setPlannedHandoverDate(moment((_b = data === null || data === void 0 ? void 0 : data.car) === null || _b === void 0 ? void 0 : _b.plannedHandoverDate));
            setAvailableApplicationReasons(data.applicationReasons.available.map(function (reason) {
                var _a;
                return (__assign(__assign({}, reason), { selected: _.some(data.applicationReasons.selected, { id: reason.id }), requestedNetDiscount: ((_a = _.find(data.applicationReasons.selected, { id: reason.id })) === null || _a === void 0 ? void 0 : _a.requestedNetDiscount) || 0 }));
            }));
            setAttachments(application.attachments.map(function ($) { return ({ name: $.fileName, location: $.location }); }));
            return data;
        })
            .then(function (applicationDraft) { return Promise.all([
            userCards.get(applicationDraft.applicantId),
            organizations.load(applicationDraft.dealerId)
        ]); })
            .then(function (_a) {
            var userCard = _a[0], organization = _a[1];
            setApplicant(__assign(__assign({}, userCard), { organizationName: organization.organizationName }));
        })
            .catch(function () { });
        clientParties.load(clientIdFrom(application.party.id))
            .then(function (data) {
            return setAvailableParties(data.map(function (party) { return ({
                value: party,
                label: partyDescription(party)
            }); }));
        })
            .catch(function () { });
    }, [application]);
    useEffect(function () {
        if (selectedPartyId && availableParties) {
            var selectedParty_1 = _.chain(availableParties).map(function ($) { return $.value; }).filter(function ($) { return selectedPartyId === $.id; }).first().value();
            setSelectedParty(selectedParty_1);
        }
    }, [selectedPartyId, availableParties]);
    useEffect(function () {
        if (selectedParty) {
            setLoadingPartyExtraSaleDiscount(true);
            concessions.listActiveForIdentificationNumber(selectedParty.identificationNumber.value)
                .then(function (data) { return setConcessionsOfParty(data); })
                .catch(function () { });
            cars.byPartyIdentificationNumber(selectedParty.identificationNumber.value, formatDate(moment(Date.now()).subtract(4, 'years')), formatDate(moment(Date.now())))
                .then(function (data) { return setPartyCars(data); })
                .catch(function () { });
            extraSaleDiscounts.listForIdentificationNumber(selectedParty.identificationNumber.value)
                .then(function (data) {
                setPartyExtraSaleDiscounts(data);
                setLoadingPartyExtraSaleDiscount(false);
            })
                .catch(function () { });
        }
    }, [selectedParty]);
    useEffect(function () {
        if (_.isFinite(carNetListPrice)) {
            setCarGrossListPrice(carNetListPrice * 1.23);
        }
        else {
            setCarGrossListPrice(undefined);
        }
    }, [carNetListPrice]);
    useEffect(function () {
        if (application && application.car && application.id) {
            loadCar(application.car.commonOrderNumber);
        }
    }, [application]);
    function searchCars(phrase) {
        return carDefinitions.search(phrase);
    }
    function clientIdFrom(partyId) {
        return partyId.split('#')[0];
    }
    function loadCar(commonOrderNumber) {
        setLoading(true);
        carDefinitions.load(commonOrderNumber)
            .then(function (carDefinition) {
            setCarDefinition(carDefinition);
            setCarNetListPrice(carDefinition.approximateListPrice || 0);
            return carDefinition;
        })
            .then(function (carDefinition) { return carDefinition.orderId ? concessions.loadSettlementOfOrder(carDefinition.orderId) : undefined; })
            .then(function (settlement) { return setSettlement(settlement); }, function () { })
            .catch(function () { })
            .finally(function () { return setLoading(false); });
    }
    function submitApplication() {
        (!application.id ? submit() : correct())
            .then(function () { return window.location.href = '#/dashboard'; })
            .catch(function () { });
        function submit() {
            return extraSaleDiscounts.submit({
                funding: funding,
                applicationReasons: {
                    available: applicationDraft.applicationReasons.available,
                    selected: availableApplicationReasons
                        .filter(function ($) { return $.selected; })
                        .map(function ($) { return ({ id: $.id, requestedNetDiscount: $.requestedNetDiscount }); })
                },
                carsSalePotential: carsSalePotential,
                car: {
                    commonOrderNumber: carDefinition.commonOrderNumber,
                    netListPrice: carNetListPrice,
                    plannedHandoverDate: formatDate(plannedHandoverDate)
                },
                party: {
                    id: selectedPartyId
                },
                comment: comment,
                attachments: attachments.map(function ($) { return ({ fileName: $.name, location: $.location }); })
            });
        }
        function correct() {
            return extraSaleDiscounts.correct(application.id, {
                funding: {
                    fundingForm: {
                        selected: funding.fundingForms.selected
                    },
                    cfmProvider: {
                        selected: funding.cfmProviders.selected
                    },
                    leasingProvider: {
                        selected: funding.leasingProviders.selected
                    }
                },
                applicationReasons: {
                    selected: availableApplicationReasons
                        .filter(function ($) { return $.selected; })
                        .map(function ($) { return ({ id: $.id, requestedNetDiscount: $.requestedNetDiscount }); })
                },
                carsSalePotential: carsSalePotential,
                car: {
                    netListPrice: carNetListPrice,
                    plannedHandoverDate: formatDate(plannedHandoverDate)
                },
                comment: comment,
                attachments: attachments.map(function ($) { return ({ fileName: $.name, location: $.location }); })
            });
        }
    }
    return {
        applicant: applicant,
        availableParties: availableParties,
        setSelectedPartyId: setSelectedPartyId,
        selectedPartyId: selectedPartyId,
        selectedParty: selectedParty,
        partyCars: partyCars,
        applicationDraft: applicationDraft,
        carNetListPrice: carNetListPrice,
        setCarNetListPrice: setCarNetListPrice,
        carGrossListPrice: carGrossListPrice,
        applicationReasons: {
            available: availableApplicationReasons,
            onSelectionToggle: function (applicationReasonId) {
                setAvailableApplicationReasons(function (prevState) { return prevState.map(function (reason) {
                    if (reason.id === applicationReasonId) {
                        reason.selected = !reason.selected;
                    }
                    return reason;
                }); });
            },
            onRequestedNetDiscountChange: function (applicationReasonId, requestedNetDiscount) {
                setAvailableApplicationReasons(function (prevState) { return prevState.map(function (reason) {
                    if (reason.id === applicationReasonId) {
                        reason.requestedNetDiscount = requestedNetDiscount;
                    }
                    return reason;
                }); });
            }
        },
        carsSalePotential: carsSalePotential,
        setCarsSalePotential: setCarsSalePotential,
        comment: comment,
        setComment: setComment,
        setFunding: setFunding,
        plannedHandoverDate: plannedHandoverDate,
        setPlannedHandoverDate: setPlannedHandoverDate,
        carDefinition: carDefinition,
        settlement: settlement,
        loading: loading,
        concessions: concessionsOfParty,
        partyExtraSaleDiscounts: partyExtraSaleDiscounts,
        loadingPartyExtraSaleDiscount: loadingPartyExtraSaleDiscount,
        submitApplication: submitApplication,
        searchCars: searchCars,
        loadCar: loadCar,
        attachments: attachments,
        setAttachments: setAttachments,
        isNew: function () { return !(application === null || application === void 0 ? void 0 : application.id); }
    };
}
