// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .vcpDiscountSettlementWrapper {
    padding: 24px 0 40px 0;
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .vcpDiscountSettlementTable {
    width: 100%;
}

#new-dol .vcpDiscountSettlementHeadCell {
    font-weight: 500;
    padding: 6px 8px;
}

#new-dol .vcpDiscountSettlementTableRow:nth-child(odd) {
    background-color: var(--background-very-light-grey);
}

#new-dol .vcpDiscountSettlementBodyCell {
    padding: 14px 8px;
}

#new-dol .vcpDiscountSettlementOrderDateCell {
    padding-left: 16px;
    width: 11%;
}

#new-dol .vcpDiscountSettlementDiscountGroupCell {
    width: 25%;
}

#new-dol .vcpDiscountSettlementCatalogPriceNetCell {
    width: 14%;
}

#new-dol .vcpDiscountSettlementClientDiscountNetCell {
    width: 12%;
}

#new-dol .vcpDiscountSettlementVcpDiscountNetCell {
    width: 14%;
}

#new-dol .vcpDiscountSettlementFundingCell {
    width: 15%;
}

#new-dol .vcpDiscountSettlementStatusCell {
    width: 9%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/tradeIn/vcpDiscountSettlement/vcpDiscountSettlement.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb","sourcesContent":[".vcpDiscountSettlementWrapper {\n    padding: 24px 0 40px 0;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.vcpDiscountSettlementTable {\n    width: 100%;\n}\n\n.vcpDiscountSettlementHeadCell {\n    font-weight: 500;\n    padding: 6px 8px;\n}\n\n.vcpDiscountSettlementTableRow:nth-child(odd) {\n    background-color: var(--background-very-light-grey);\n}\n\n.vcpDiscountSettlementBodyCell {\n    padding: 14px 8px;\n}\n\n.vcpDiscountSettlementOrderDateCell {\n    padding-left: 16px;\n    width: 11%;\n}\n\n.vcpDiscountSettlementDiscountGroupCell {\n    width: 25%;\n}\n\n.vcpDiscountSettlementCatalogPriceNetCell {\n    width: 14%;\n}\n\n.vcpDiscountSettlementClientDiscountNetCell {\n    width: 12%;\n}\n\n.vcpDiscountSettlementVcpDiscountNetCell {\n    width: 14%;\n}\n\n.vcpDiscountSettlementFundingCell {\n    width: 15%;\n}\n\n.vcpDiscountSettlementStatusCell {\n    width: 9%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
