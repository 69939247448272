var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect, useState } from 'react';
import { sortingAvailableOptions } from './SearchCarSection.component';
import { useNewOfferFromStockApi } from 'pages/newOfferFromStock/useNewOfferFromStockApi';
import { useStatus } from 'common/statusContext';
import ReactDOM from 'react-dom';
import { formatShortDate } from 'utils/formatShortDate';
import SessionStorage from 'common/sessionStorage';
import { immutableSort } from 'utils/immutableSort';
export var dateRangeFilterKeys = ['lastDateForOrderChange', 'deliveryDate'];
var priceRangesOptions = [
    {
        label: '100 tys - 150 tys',
        value: '100 tys - 150 tys',
        from: 100000,
        to: 150000
    },
    {
        label: '150 tys - 200 tys',
        value: '150 tys - 200 tys',
        from: 150000,
        to: 200000
    },
    {
        label: '200 tys - 250 tys',
        value: '200 tys - 250 tys',
        from: 200000,
        to: 250000
    },
    {
        label: '250 tys - 300 tys',
        value: '250 tys - 300 tys',
        from: 250000,
        to: 300000
    },
    {
        label: 'pow. 300 tys',
        value: 'pow. 300 tys',
        from: 300000,
        to: 100000000
    }
];
var ITEMS_PER_PAGINATION_PAGE = 10;
export function useSearchCarSection() {
    var _a = useState('stock-cars'), searchingType = _a[0], setSearchingType = _a[1];
    var _b = useState(undefined), filtersOptions = _b[0], setFiltersOptions = _b[1];
    var _c = useState(undefined), selectedFilters = _c[0], setSelectedFilters = _c[1];
    var _d = useState(sortingAvailableOptions[0]), seletedSorting = _d[0], setSelectedSorting = _d[1];
    var _e = useState(0), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = useState(0), totalPages = _f[0], setTotalPages = _f[1];
    var _g = useState(), stockCars = _g[0], setStockCars = _g[1];
    var _h = useState(0), totalCars = _h[0], setTotalCars = _h[1];
    var _j = useState(true), carsDataAreUpToDate = _j[0], setCarsDataAreUpToDate = _j[1];
    var newOfferFromStockApi = useNewOfferFromStockApi();
    var setStatus = useStatus().setStatus;
    function buildBodyForSearchHelper(selectedFilters) {
        var _a, _b, _c, _d;
        var filtersForBody = __assign({}, selectedFilters);
        delete filtersForBody.model;
        var body = {};
        for (var _i = 0, _e = Object.entries(filtersForBody); _i < _e.length; _i++) {
            var _f = _e[_i], key = _f[0], value = _f[1];
            if (!Array.isArray(value)) {
                body = __assign(__assign({}, body), (_a = {}, _a[key] = value.value, _a));
            }
            else if (key === 'lastDateForOrderChange' || key === 'deliveryDate') {
                if (value[0] === null && value[1] === null) {
                    body = __assign({}, body);
                }
                else {
                    body = __assign(__assign({}, body), (_b = {}, _b[key] = {
                        startDate: formatShortDate(value[0]),
                        endDate: formatShortDate(value[1])
                    }, _b));
                }
            }
            else if (key === 'priceRanges') {
                if (value.length === 0) {
                    body = __assign({}, body);
                }
                else {
                    body = __assign(__assign({}, body), (_c = {}, _c[key] = value.map(function (v) {
                        return {
                            from: v.from,
                            to: v.to
                        };
                    }), _c));
                }
            }
            else {
                if (value.length === 0) {
                    body = __assign({}, body);
                }
                else {
                    body = __assign(__assign({}, body), (_d = {}, _d[key] = value.map(function (v) { return v.value; }), _d));
                }
            }
        }
        return body;
    }
    function filterNullAndDubleOptions(filterOptions) {
        var withoutNulls = filterOptions.filter(function (option) { return option.value !== null && option.label !== null && option.label.toString().toUpperCase() !== 'NONE'; });
        var withoutDuplicates = withoutNulls.reduce(function (uniqueOptions, currentOption) {
            if (uniqueOptions.some(function (option) { return option.value === currentOption.value; })) {
                return uniqueOptions;
            }
            uniqueOptions.push(currentOption);
            return uniqueOptions;
        }, []);
        return withoutDuplicates;
    }
    function setOptionsHelper(filters) {
        return {
            'modelYears': filters.MODEL_YEAR ? filterNullAndDubleOptions(filters.MODEL_YEAR) : [],
            'drives': filters.DRIVE_TYPE ? filterNullAndDubleOptions(filters.DRIVE_TYPE) : [],
            'engines': filters.ENGINE ? filterNullAndDubleOptions(filters.ENGINE) : [],
            'types': filters.ORDER_TYPE ? filterNullAndDubleOptions(filters.ORDER_TYPE) : [],
            'carLocations': filters.CAR_LOCATION ? filterNullAndDubleOptions(filters.CAR_LOCATION) : [],
            'versions': filters.VERSION ? filterNullAndDubleOptions(filters.VERSION) : [],
            'colors': filters.COLOR ? filterNullAndDubleOptions(filters.COLOR) : [],
            'upholsteries': filters.UPHOLSTERY ? filterNullAndDubleOptions(filters.UPHOLSTERY) : [],
            'priceRanges': priceRangesOptions,
            'stockType': filters.STOCK ? filterNullAndDubleOptions(filters.STOCK) : [],
        };
    }
    // function filtersBodyTemplate (selectedFilters: SelectedFilters) {
    //     const filters = {
    //         ...buildBodyForSearchHelper(selectedFilters),
    //         pno3: selectedFilters.model.value
    //     }
    //         return filters;
    // }
    function onFilterChange(filterType, values) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                handleSetSelectedFilter(filterType, values);
                // const nextSelectedFilters = handleSetSelectedFilter(filterType, values);
                // const apiBody = filtersBodyTemplate(nextSelectedFilters);
                // const filtersResponse = await newOfferFromStockApi.getAvailableOptions(apiBody);
                // if(filtersResponse && filtersResponse.status === 200) {
                //     const filters = filtersResponse.data;
                //     handleSetFiltersOptions(setOptionsHelper(filters));
                //     SessionStorage.set('stockFiltersOptions', {...filtersOptions, ...setOptionsHelper(filters)});
                // }
                setCarsDataAreUpToDate(false);
                SessionStorage.set('stockCarsDataAreUpToDate', false);
                return [2 /*return*/];
            });
        });
    }
    function clearSelectedFilters() {
        setSelectedFilters(undefined);
        SessionStorage.remove('stockSelectedFilters');
    }
    function handleSetFiltersOptions(filtersOptions) {
        setFiltersOptions(function (currentFiltersOptions) {
            return __assign(__assign({}, currentFiltersOptions), filtersOptions);
        });
    }
    function handleSetSelectedFilter(filterType, option) {
        var _a;
        var nextSelectedFilters = __assign(__assign({}, selectedFilters), (_a = {}, _a[filterType] = option, _a));
        setSelectedFilters(nextSelectedFilters);
        SessionStorage.set('stockSelectedFilters', nextSelectedFilters);
        return nextSelectedFilters;
    }
    function handleDeleteSelectedFilter(filterType, value) {
        var _a;
        var nextSelectedFilters = __assign({}, selectedFilters);
        if (Array.isArray(nextSelectedFilters[filterType])) {
            var editedSelectedFilter = nextSelectedFilters[filterType].filter(function (v) { return v.value !== value; });
            if (editedSelectedFilter.length === 0) {
                var newSelectedFilters = __assign({}, nextSelectedFilters);
                delete newSelectedFilters[filterType];
                nextSelectedFilters = newSelectedFilters;
            }
            else {
                nextSelectedFilters = __assign(__assign({}, nextSelectedFilters), (_a = {}, _a[filterType] = editedSelectedFilter, _a));
            }
        }
        else {
            var newSelectedFilters = __assign({}, nextSelectedFilters);
            delete newSelectedFilters[filterType];
            nextSelectedFilters = newSelectedFilters;
        }
        setSelectedFilters(nextSelectedFilters);
        SessionStorage.set('stockSelectedFilters', nextSelectedFilters);
        return nextSelectedFilters;
    }
    function clearAllSelectedFilters() {
        setSelectedFilters(undefined);
        setCarsDataAreUpToDate(false);
        SessionStorage.remove('stockSelectedFilters');
        SessionStorage.remove('stockCarsDataAreUpToDate');
    }
    function handleChangeSearchingType(value) {
        setSearchingType(value);
        SessionStorage.set('stockSearchingType', value);
        clearStockCars();
        clearSelectedFilters();
        setTotalPages(0);
        SessionStorage.remove('stockTotalPages');
        setSelectedSorting(sortingAvailableOptions[0]);
        SessionStorage.remove('stockSelectedSorting');
    }
    function clearStockCars() {
        setStockCars(undefined);
        setTotalCars(0);
        SessionStorage.remove('stockCars');
        SessionStorage.remove('stockTotalCars');
    }
    function onShowSelectedCar(car) {
        setStockCars([car]);
        SessionStorage.set('stockCars', [car]);
    }
    var sortingAvailable = stockCars && stockCars.length > 1;
    function onSortingChange(_, option) {
        setSelectedSorting(option);
        SessionStorage.set('stockSelectedSorting', option);
        if (sortingAvailable) {
            setCurrentPage(0);
            SessionStorage.set('stockCurrentPage', 0);
            var body = buildBodyForSearchHelper(selectedFilters);
            onStockCarSearchClick(selectedFilters.model.value.toString(), body, option.value.toString(), 0);
        }
    }
    function onDateRangeChange(filterType, dates) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                handleSetSelectedFilter(filterType, dates);
                // const nextSelectedFilters = handleSetSelectedFilter(filterType, dates);
                // const apiBody = filtersBodyTemplate(nextSelectedFilters);
                if (dates[1] !== null) {
                    // const filtersResponse = await newOfferFromStockApi.getAvailableOptions(apiBody);
                    // if(filtersResponse && filtersResponse.status === 200) {
                    //     const filters = filtersResponse.data;
                    //     handleSetFiltersOptions(setOptionsHelper(filters));
                    //     SessionStorage.set('stockFiltersOptions', {...filtersOptions, ...setOptionsHelper(filters)});
                    // }
                    setCarsDataAreUpToDate(false);
                    SessionStorage.set('stockCarsDataAreUpToDate', false);
                }
                return [2 /*return*/];
            });
        });
    }
    function onModelChange(_, option) {
        return __awaiter(this, void 0, void 0, function () {
            var filtersResponse, filters;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setSelectedFilters({ 'model': option });
                        return [4 /*yield*/, newOfferFromStockApi.getAvailableOptions({ pno3: option.value.toString() })];
                    case 1:
                        filtersResponse = _a.sent();
                        if (filtersResponse && filtersResponse.status === 200) {
                            filters = filtersResponse.data;
                            handleSetFiltersOptions(setOptionsHelper(filters));
                            SessionStorage.set('stockFiltersOptions', __assign(__assign({}, filtersOptions), setOptionsHelper(filters)));
                        }
                        setCarsDataAreUpToDate(false);
                        SessionStorage.set('stockSelectedFilters', { 'model': option });
                        SessionStorage.set('stockCarsDataAreUpToDate', false);
                        return [2 /*return*/];
                }
            });
        });
    }
    function onSearchClick() {
        setCurrentPage(0);
        SessionStorage.set('stockCurrentPage', 0);
        var body = buildBodyForSearchHelper(selectedFilters);
        onStockCarSearchClick(selectedFilters.model.value.toString(), body, seletedSorting.value.toString(), 0);
    }
    function loadOptions(inputValue) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (inputValue.length < 3) {
                    return [2 /*return*/];
                }
                return [2 /*return*/, newOfferFromStockApi.searchCars(searchingType, inputValue)];
            });
        });
    }
    function stockCarSearchChangeHandler(option) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedCar;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, newOfferFromStockApi.getCar(option.commonOrderNumber)];
                    case 1:
                        selectedCar = _a.sent();
                        setStatus('success');
                        onShowSelectedCar(selectedCar);
                        setTotalCars(1);
                        SessionStorage.set('stockTotalCars', 1);
                        clearSelectedFilters();
                        return [2 /*return*/];
                }
            });
        });
    }
    ;
    function onStockCarSearchClick(pno3, body, sorting, currentPage) {
        return __awaiter(this, void 0, void 0, function () {
            var showFilteredCarsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, newOfferFromStockApi.showFilteredCars(pno3, body, sorting, currentPage, ITEMS_PER_PAGINATION_PAGE)];
                    case 1:
                        showFilteredCarsResponse = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStockCars(showFilteredCarsResponse.content);
                            setTotalPages(showFilteredCarsResponse.totalPages);
                            setTotalCars(showFilteredCarsResponse.totalElements);
                            SessionStorage.set('stockCars', showFilteredCarsResponse.content);
                            SessionStorage.set('stockTotalPages', showFilteredCarsResponse.totalPages);
                            SessionStorage.set('stockTotalCars', showFilteredCarsResponse.totalElements);
                            setCarsDataAreUpToDate(true);
                            SessionStorage.set('stockCarsDataAreUpToDate', true);
                            setStatus('success');
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function changePageHandler(selectedPage) {
        return __awaiter(this, void 0, void 0, function () {
            var body, showFilteredCarsResponse_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!selectedFilters) return [3 /*break*/, 1];
                        setStockCars(undefined);
                        SessionStorage.remove('stockCars');
                        setCurrentPage(0);
                        SessionStorage.remove('stockCurrentPage');
                        setTotalCars(0);
                        SessionStorage.remove('stockTotalCars');
                        setTotalPages(0);
                        SessionStorage.remove('stockTotalPages');
                        return [3 /*break*/, 3];
                    case 1:
                        setStatus('pending');
                        body = buildBodyForSearchHelper(selectedFilters);
                        return [4 /*yield*/, newOfferFromStockApi.showFilteredCars(selectedFilters.model.value, body, seletedSorting.value, selectedPage, ITEMS_PER_PAGINATION_PAGE)];
                    case 2:
                        showFilteredCarsResponse_1 = _a.sent();
                        ReactDOM.unstable_batchedUpdates(function () {
                            setStockCars(showFilteredCarsResponse_1.content);
                            setCurrentPage(selectedPage);
                            SessionStorage.set('stockCars', showFilteredCarsResponse_1.content);
                            SessionStorage.set('stockCurrentPage', selectedPage);
                            setStatus('success');
                        });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    }
    function onDeleteFilter(filterType, filterValue) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (filterType === 'model') {
                    setSelectedFilters(undefined);
                    SessionStorage.remove('stockSelectedFilters');
                    setCarsDataAreUpToDate(false);
                    SessionStorage.set('stockCarsDataAreUpToDate', false);
                }
                else if (dateRangeFilterKeys.includes(filterType)) {
                    handleSetSelectedFilter(filterType, [null, null]);
                    // const nextSelectedFilters = handleSetSelectedFilter(filterType, [null, null]);
                    // const apiBody = filtersBodyTemplate(nextSelectedFilters);
                    // const filtersResponse = await newOfferFromStockApi.getAvailableOptions(apiBody);
                    // if(filtersResponse && filtersResponse.status === 200) {
                    //     const filters = filtersResponse.data;
                    //     handleSetFiltersOptions(setOptionsHelper(filters));
                    //     SessionStorage.set('stockFiltersOptions', {...filtersOptions, ...setOptionsHelper(filters)});
                    // }
                    setCarsDataAreUpToDate(false);
                    SessionStorage.set('stockCarsDataAreUpToDate', false);
                }
                else {
                    handleDeleteSelectedFilter(filterType, filterValue);
                    // const nextSelectedFilters = handleDeleteSelectedFilter(filterType as keyof Omit<SelectedFilters, 'model' | 'lastDateForOrderChange' | 'deliveryDate'>, filterValue);
                    // const apiBody = filtersBodyTemplate(nextSelectedFilters);
                    // const filtersResponse = await newOfferFromStockApi.getAvailableOptions(apiBody);
                    // if(filtersResponse && filtersResponse.status === 200) {
                    //     const filters = filtersResponse.data;
                    //     handleSetFiltersOptions(setOptionsHelper(filters));
                    //     SessionStorage.set('stockFiltersOptions', {...filtersOptions, ...setOptionsHelper(filters)});
                    // }
                    setCarsDataAreUpToDate(false);
                    SessionStorage.set('stockCarsDataAreUpToDate', false);
                }
                return [2 /*return*/];
            });
        });
    }
    useEffect(function () {
        function getModels() {
            return __awaiter(this, void 0, void 0, function () {
                var availableModels, availableModelsOptions, sortedAvailableModelsOptions;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, newOfferFromStockApi.getAvailableModels()];
                        case 1:
                            availableModels = _a.sent();
                            availableModelsOptions = filterNullAndDubleOptions(availableModels);
                            sortedAvailableModelsOptions = immutableSort(availableModelsOptions, function (modelA, modelB) { return modelA.label.toString().localeCompare(modelB.label.toString()); });
                            handleSetFiltersOptions({ 'model': sortedAvailableModelsOptions });
                            SessionStorage.set('stockFiltersOptions', { 'model': sortedAvailableModelsOptions });
                            setCarsDataAreUpToDate(false);
                            SessionStorage.set('stockCarsDataAreUpToDate', false);
                            setStatus('success');
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (SessionStorage.has('stockSelectedFilters')) {
            var selectedFiltersFromSessionStorage = SessionStorage.get('stockSelectedFilters');
            var formattedSelectedFiltersFromSessionStorage = __assign(__assign({}, selectedFiltersFromSessionStorage), { lastDateForOrderChange: selectedFiltersFromSessionStorage.lastDateForOrderChange ? selectedFiltersFromSessionStorage.lastDateForOrderChange.map(function (date) { return date ? new Date(date) : null; }) : [null, null], deliveryDate: selectedFiltersFromSessionStorage.deliveryDate ? selectedFiltersFromSessionStorage.deliveryDate.map(function (date) { return date ? new Date(date) : null; }) : [null, null] });
            setSelectedFilters(formattedSelectedFiltersFromSessionStorage);
        }
        var filtersOptionsFromSessionStorage = SessionStorage.get('stockFiltersOptions');
        filtersOptionsFromSessionStorage ? handleSetFiltersOptions(filtersOptionsFromSessionStorage) : null;
        if (SessionStorage.has('stockCarsDataAreUpToDate')) {
            var stockCarsDataAreUpToDateFromSessionStorage = SessionStorage.get('stockCarsDataAreUpToDate');
            setCarsDataAreUpToDate(stockCarsDataAreUpToDateFromSessionStorage);
        }
        var stockCarsFromSessionStorage = SessionStorage.get('stockCars');
        stockCarsFromSessionStorage ? setStockCars(stockCarsFromSessionStorage) : null;
        var stockTotalPagesFromSessionStorge = SessionStorage.get('stockTotalPages');
        stockTotalPagesFromSessionStorge ? setTotalPages(stockTotalPagesFromSessionStorge) : null;
        var stockCurrentPageFromSessionStorage = SessionStorage.get('stockCurrentPage');
        stockCurrentPageFromSessionStorage ? setCurrentPage(stockCurrentPageFromSessionStorage) : null;
        var stockTotalCarsFromSessionStorage = SessionStorage.get('stockTotalCars');
        stockTotalCarsFromSessionStorage ? setTotalCars(stockTotalCarsFromSessionStorage) : null;
        var stockSelectedSortingFromSessionStorage = SessionStorage.get('stockSelectedSorting');
        stockSelectedSortingFromSessionStorage ? setSelectedSorting(stockSelectedSortingFromSessionStorage) : null;
        var stockSearchingTypeFromSessionStorage = SessionStorage.get('stockSearchingType');
        stockSearchingTypeFromSessionStorage ? setSearchingType(stockSearchingTypeFromSessionStorage) : null;
        if (filtersOptions === undefined && !SessionStorage.has('stockFiltersOptions')) {
            getModels();
        }
    }, []);
    useEffect(function () {
        return function () {
            SessionStorage.remove('stockSelectedFilters');
            SessionStorage.remove('stockCarsDataAreUpToDate');
            SessionStorage.remove('stockFiltersOptions');
            SessionStorage.remove('stockCars');
            SessionStorage.remove('stockTotalPages');
            SessionStorage.remove('stockCurrentPage');
            SessionStorage.remove('stockTotalCars');
            SessionStorage.remove('stockSelectedSorting');
            SessionStorage.remove('stockSearchingType');
        };
    }, []);
    return {
        searchingType: searchingType,
        handleChangeSearchingType: handleChangeSearchingType,
        stockCarSearchChangeHandler: stockCarSearchChangeHandler,
        filtersOptions: filtersOptions,
        loadOptions: loadOptions,
        selectedFilters: selectedFilters,
        onModelChange: onModelChange,
        onFilterChange: onFilterChange,
        onDateRangeChange: onDateRangeChange,
        onDeleteFilter: onDeleteFilter,
        clearAllSelectedFilters: clearAllSelectedFilters,
        seletedSorting: seletedSorting,
        onSortingChange: onSortingChange,
        currentPage: currentPage,
        totalPages: totalPages,
        changePageHandler: changePageHandler,
        stockCars: stockCars,
        onSearchClick: onSearchClick,
        totalCars: totalCars,
        carsDataAreUpToDate: carsDataAreUpToDate
    };
}
