import { useState } from 'react';
export function useAdditionalInfosSection() {
    var _a = useState(''), additionalInfo = _a[0], setAdditionalInfo = _a[1];
    function onAdditionalInfoChange(event) {
        setAdditionalInfo(event.currentTarget.value);
    }
    return {
        additionalInfo: additionalInfo,
        onAdditionalInfoChange: onAdditionalInfoChange
    };
}
