import React from 'react';
import './tradeInDiscountSection.css';
import { List } from 'components/list/List.component';
import { TextAmount } from 'components/textAmount/TextAmount';
import { ChangesHistory } from '../changesHistory/ChangesHistory';
import { useTradeInApi } from '../useTradeInApi';
import { openFile } from 'common/openFile';
import { TextNumber } from 'components/textNumber/TextNumber';
export function TradeInDiscountPreviewSection(_a) {
    var tradeInDiscountPreviewSectionData = _a.tradeInDiscountPreviewSectionData;
    var vin = tradeInDiscountPreviewSectionData.vin, firstRegistrationDate = tradeInDiscountPreviewSectionData.firstRegistrationDate, repurchaseDate = tradeInDiscountPreviewSectionData.repurchaseDate, mileage = tradeInDiscountPreviewSectionData.mileage, selectedSelectSalesman = tradeInDiscountPreviewSectionData.selectedSelectSalesman, carPurchaseDocument = tradeInDiscountPreviewSectionData.carPurchaseDocument, registrationCertificateCopy = tradeInDiscountPreviewSectionData.registrationCertificateCopy, newCarPurchaseDocument = tradeInDiscountPreviewSectionData.newCarPurchaseDocument, handoverProtocol = tradeInDiscountPreviewSectionData.handoverProtocol, consentDocument = tradeInDiscountPreviewSectionData.consentDocument, otherDocuments = tradeInDiscountPreviewSectionData.otherDocuments, supportVcpAmountNet = tradeInDiscountPreviewSectionData.supportVcpAmountNet, changesHistory = tradeInDiscountPreviewSectionData.changesHistory;
    var tradeInApi = useTradeInApi();
    function handleFileNameClick(fileUrl, fileName) {
        tradeInApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    return (React.createElement("div", { className: "tradeInDiscountSectionWrapper" },
        React.createElement("div", { className: "tradeInDiscountSectionGridWrapper" },
            React.createElement("div", { className: "tradeInSectionGridColumn" },
                React.createElement("div", { className: "tradeInLabelValuePair" },
                    React.createElement("div", { className: "tradeInLabel" }, "VIN samochodu odkupionego:"),
                    React.createElement("div", { className: "tradeInValue" }, vin ? vin : 'brak')),
                React.createElement("div", { className: "tradeInLabelValuePair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Data pierwszej rejestracji:"),
                    React.createElement("div", { className: "tradeInValue" }, firstRegistrationDate ? firstRegistrationDate : 'brak')),
                React.createElement("div", { className: "tradeInLabelValuePair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Data odkupu samochodu:"),
                    React.createElement("div", { className: "tradeInSubLabel" }, "(z FV lub umowy kupna)"),
                    React.createElement("div", { className: "tradeInValue" }, repurchaseDate ? repurchaseDate : 'brak')),
                React.createElement("div", { className: "tradeInLabelValuePair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Przebieg (km):"),
                    mileage ? React.createElement("div", { className: "tradeInValue" },
                        React.createElement(TextNumber, null, mileage)) : React.createElement("div", { className: "tradeInValue" }, "brak")),
                React.createElement("div", { className: "tradeInLabelValuePair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Handlowiec Selekt:"),
                    React.createElement("div", { className: "tradeInValue" }, selectedSelectSalesman ? selectedSelectSalesman : 'brak'))),
            React.createElement("div", { className: "tradeInSectionGridColumn" },
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInLabel" }, "Dokumenty - odkup samochodu u\u017Cywanego*:"),
                    React.createElement("div", { className: "tradeInFileWrapper" },
                        React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "FV lub umowa kupna auta")),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper" }, carPurchaseDocument.length > 0 && (React.createElement(List.Root, null, carPurchaseDocument.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); })))),
                    React.createElement("div", { className: "tradeInFileWrapper" },
                        React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "Kopia dowodu rejestracyjnego")),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, registrationCertificateCopy.length > 0 && (React.createElement(List.Root, null, registrationCertificateCopy.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); }))))),
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInLabel" }, "Dokumenty - zakup pojazdu nowego*:"),
                    React.createElement("div", { className: "tradeInFileWrapper" },
                        React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "FV sprzeda\u017Cy")),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper" }, newCarPurchaseDocument.length > 0 && (React.createElement(List.Root, null, newCarPurchaseDocument.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); })))),
                    React.createElement("div", { className: "tradeInFileWrapper" },
                        React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "Protok\u00F3\u0142 wydania")),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, handoverProtocol.length > 0 && (React.createElement(List.Root, null, handoverProtocol.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); }))))),
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInSectionFileWrapper" },
                        React.createElement("div", { className: "tradeInLabel" }, "Dokument zgody*:")),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, consentDocument.length > 0 && (React.createElement(List.Root, null, consentDocument.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); }))))),
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInSectionFileWrapper" },
                        React.createElement("div", { className: "tradeInLabel" }, "Inne dokumenty:")),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, otherDocuments.length > 0 && (React.createElement(List.Root, null, otherDocuments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName)))); }))))))),
        React.createElement("div", { className: 'tradeInSectionSupportAmountWrapper' },
            React.createElement("div", { className: "tradeInSupportAmountLabel" }, "Kwota wsparcia VCP:"),
            React.createElement("div", { className: "tradeInSupportAmountValue" },
                React.createElement(TextAmount, null, supportVcpAmountNet),
                "netto")),
        React.createElement(ChangesHistory, { changesHistory: changesHistory })));
}
