var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import './auditDocumentsSection.css';
import { AuditDocumentationConfig } from '../auditDocumentsSection/AuditDocumentationConfig';
import { HorizontalLine } from '../horizontalLine/HorizontalLine';
import { List } from 'components/list/List.component';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { Button } from 'components/button/Button.component';
import AuthToken from 'common/authToken';
import { openFile } from 'common/openFile';
export var AuditDocumentsSection = withCancellation(function (_a) {
    var auditDocuments = _a.auditDocuments, clearAuditDocumentsState = _a.clearAuditDocumentsState;
    var auditDocumentsAttachments = auditDocuments.attachments;
    var orderSheetExists = auditDocuments.orderSheetExists;
    var orderId = auditDocuments.id;
    var auditDocumentsStatus = auditDocuments.status;
    var areSomeDocuments = Object.keys(auditDocumentsAttachments).length > 0;
    var documentsReturned = auditDocumentsStatus === 'RETURNED';
    var auditDocumentsList = AuditDocumentationConfig();
    var specialOfferApi = useSpecialOfferApi();
    function getDocumentsByKey(key) {
        return auditDocumentsAttachments[key];
    }
    function handleFileNameClick(fileUrl, fileName) {
        specialOfferApi
            .loadLocation(fileUrl)
            .then(function (response) { return openFile(response, fileName); })
            .catch(function () { });
    }
    function returnDocuments() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, specialOfferApi.returnAuditDocuments(orderId)];
                    case 1:
                        _a.sent();
                        clearAuditDocumentsState();
                        return [2 /*return*/];
                }
            });
        });
    }
    var authToken = AuthToken.get();
    return auditDocumentsList ? (React.createElement("div", { className: "auditDocumentsSectionWrapper" }, areSomeDocuments && !documentsReturned ? (React.createElement("div", null,
        orderSheetExists ? (React.createElement("div", null,
            React.createElement("div", { className: "auditDocumentsLabel" }, "Arkusz zam\u00F3wienia"),
            React.createElement(HorizontalLine, { color: "#D5D5D5" }),
            React.createElement("div", { className: "auditDocumentsList" },
                React.createElement(List.Root, null,
                    React.createElement(List.Item, { key: "orderSheet" },
                        React.createElement("div", { className: "auditDocumentAttachmentItem" },
                            React.createElement("div", { "data-testid": 'audit-documents-attachment-link-wrapper' },
                                React.createElement("a", { href: "api/orders/".concat(orderId, "/sheet/pdf?authToken=").concat(authToken), target: '_blank' }, "PDF")))))))) : null,
        auditDocumentsList.map(function (document) {
            return (React.createElement("div", { key: document.key },
                React.createElement("div", { className: "auditDocumentsLabel", "data-testid": "audit-document-item-label" }, document.label),
                React.createElement(HorizontalLine, { color: "#D5D5D5" }),
                React.createElement("div", { className: "auditDocumentsList" }, getDocumentsByKey(document.key) && getDocumentsByKey(document.key).length > 0 ? (React.createElement(List.Root, null, getDocumentsByKey(document.key).map(function (file) { return (React.createElement(List.Item, { key: file.location },
                    React.createElement("div", { className: "auditDocumentAttachmentItem" },
                        React.createElement("div", { onClick: function () {
                                return handleFileNameClick(file.location, file.fileName);
                            }, "data-testid": "audit-document-item-attachment" }, file.fileName)))); }))) : null)));
        }),
        React.createElement("div", { className: 'returnAuditDocumentsButtonWrapper' },
            React.createElement("div", { className: 'returnAuditDocumentsButton' },
                React.createElement(Button, { onClick: returnDocuments, variant: 'secondary' }, "Ode\u015Blij do uzupe\u0142nienia"))))) : (React.createElement("div", { className: "auditDocumentsLabel", "data-testid": 'no-documents' }, "Brak dokument\u00F3w audytowych")))) : (null);
});
