import React from 'react';
import './fileInput.css';
export function FileInput(_a) {
    var label = _a.label, fileRef = _a.fileRef, onFileChange = _a.onFileChange, acceptFile = _a.acceptFile, value = _a.value, id = _a.id, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return React.createElement("button", { type: 'button', className: "attachmentButton ".concat(disabled ? 'attachmentsButtonDisabled' : ''), disabled: disabled, onClick: function () {
            if (fileRef.current) {
                fileRef.current.click();
            }
        } },
        React.createElement("input", { type: 'file', accept: acceptFile, id: id, onChange: onFileChange, className: 'attachmentsInput', ref: fileRef, value: value, "data-testid": 'file-input' }),
        label);
}
