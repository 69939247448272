export function vcpDiscountName(discount) {
    if (!discount) {
        return '';
    }
    switch (discount.discountGroup.id) {
        case 'PROFESSION_GROUP':
            return "".concat(discount.discountGroup.name, " - ").concat(discount.professionGroup.name);
        case 'DETAILED_SPECIAL_OFFER':
            return "".concat(discount.discountGroup.name, " - ").concat(discount.specialOfferType.name);
        case 'DETAILED_SPECIAL_OFFER_GMA':
            return "".concat(discount.discountGroup.name, " - ").concat(discount.specialOfferGlobalMajorAccount.name);
        case 'SECOND_GMA':
            return "".concat(discount.discountGroup.name, " - ").concat(discount.secondDiscountGroupGlobalMajorAccount.name);
        default:
            return "".concat(discount.discountGroup.name);
    }
}
