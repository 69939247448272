import React from 'react';
import './additionalInfosSection.css';
import { TextArea } from 'components/textArea/TextArea.component';
export function AdditionalInfosSection(_a) {
    var additionalInfosSectionData = _a.additionalInfosSectionData;
    var additionalInfo = additionalInfosSectionData.additionalInfo, onAdditionalInfoChange = additionalInfosSectionData.onAdditionalInfoChange;
    return (React.createElement("div", { className: "additionalInfosSectionWrapper" },
        React.createElement("div", { className: 'additionalInfoTextArea' },
            React.createElement(TextArea, { id: 'additionalInfoSpecialOffer', name: 'additionalInfoSpecialOffer', placeholder: 'Wprowad\u017A komentarz', onChange: onAdditionalInfoChange, value: additionalInfo, rows: 3, disableResize: true }))));
}
