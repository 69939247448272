import React from 'react';
import './orderingPartySection.css';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { clientPartyTypeDescription } from 'common/clientPartyTypeDescription';
import ModelDictionary from 'common/modelDictionary';
import { AsyncDropdown } from 'components/dropdown/AsyncDropdown.component';
var generateTradeInCarOptionLabel = function (e) {
    return (ModelDictionary.nameFor(e.modelId) +
        ' ' +
        "(".concat(e.modelYear, ")") +
        ', ' +
        "".concat(e.dealerOrder ? 'DO: ' + e.dealerOrder + ',' : '') +
        ' ' +
        "".concat(e.commonOrderNumber ? 'CON: ' + e.commonOrderNumber : '') +
        ' ' +
        "".concat(e.vin ? ', ' + e.vin : '') +
        ' ' +
        "".concat(e.registrationNumber ? ', ' + e.registrationNumber : ''));
};
export function OrderingPartySection(_a) {
    var orderingPartySectionData = _a.orderingPartySectionData;
    var parties = orderingPartySectionData.parties, party = orderingPartySectionData.party, handleSelectedPartyChange = orderingPartySectionData.handleSelectedPartyChange, isFromPartyCard = orderingPartySectionData.isFromPartyCard, loadCarOptions = orderingPartySectionData.loadCarOptions, onCarChange = orderingPartySectionData.onCarChange, selectedCar = orderingPartySectionData.selectedCar;
    var partiesDropdownOptions = parties ? parties.map(function (party) {
        return {
            value: party.id,
            label: "".concat(clientPartyTypeDescription(party.type), " ").concat(party.name, ", ").concat(party.identificationNumber.type, ": ").concat(party.identificationNumber.value),
        };
    })
        : [];
    return (React.createElement("div", { className: 'orderingPartySectionWrapper' },
        React.createElement("div", { className: 'orderingPartySectionDropdownsContainer' },
            React.createElement("div", { className: 'orderingPartyDropdown' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Strona klienta:"),
                !isFromPartyCard ? (React.createElement("div", { className: "orderingPartyDropdown", "data-testid": "ordering-party-section-party-dropdown" },
                    React.createElement(Dropdown, { options: partiesDropdownOptions, name: "orderingParty", onChange: handleSelectedPartyChange, placeholder: "Wybierz stron\u0119*", noOptionsMessage: "Brak stron" }))) : party ? (React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-value" },
                    party.name,
                    " ",
                    party.identificationNumber.type,
                    ": ",
                    party.identificationNumber.value)) : null),
            React.createElement("div", { className: 'orderingPartyDropdown' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Samoch\u00F3d:"),
                React.createElement("div", { className: "stockCarSearch" },
                    React.createElement(AsyncDropdown, { getOptionLabel: function (e) { return generateTradeInCarOptionLabel(e); }, disabled: !party, loadOptions: loadCarOptions, placeholder: 'Model, rok modelowy, DO, CON, VIN, Numer rejestracyjny*', onChange: onCarChange, value: selectedCar }))))));
}
