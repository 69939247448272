var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import AsyncSelect from 'react-select/async';
import { ChevronDown } from './ChevronDown.component';
export function AsyncDropdown(_a) {
    var value = _a.value, getOptionLabel = _a.getOptionLabel, onChange = _a.onChange, loadOptions = _a.loadOptions, placeholder = _a.placeholder, _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.autoFocus, autoFocus = _c === void 0 ? false : _c, _d = _a.noOptionsMessage, noOptionsMessage = _d === void 0 ? function () { return null; } : _d;
    var components = {
        IndicatorSeparator: function () { return null; },
        IndicatorsContainer: function () { return React.createElement(ChevronDown, { className: "chevronDropdown ".concat(disabled ? 'chevronDisabled' : '') }); },
    };
    return (React.createElement(AsyncSelect, { value: value, isDisabled: disabled, getOptionLabel: getOptionLabel, loadOptions: loadOptions, onChange: onChange, autoFocus: autoFocus, placeholder: placeholder, noOptionsMessage: noOptionsMessage, components: components, styles: {
            container: function (provided) { return (__assign(__assign({}, provided), { fontFamily: 'var(--font-family)', color: 'var(--black-text-color)', fontSize: 14, lineHeight: '20px', maxHeight: '32px' })); },
            option: function (provided, state) { return (__assign(__assign({}, provided), { height: 40, display: 'flex', alignItems: 'center', padding: '12px 16px', fontSize: 14, color: '#141414', fontWeight: 400, backgroundColor: state.isFocused ? 'var(--light-grey)' : '', '&:active': {
                    backgroundColor: 'var(--light-grey)'
                }, fontFamily: 'var(--font-family)' })); },
            control: function (provided, status) { return (__assign(__assign({}, provided), { '&:hover': {
                    border: '1px solid var(--dropdown-border-color)',
                }, border: '1px solid var(--dropdown-border-color)', boxShadow: 'none', outline: 'none', paddingLeft: 16, paddingRight: 12, gap: 8, minHeight: 32, backgroundColor: status.isDisabled ? 'var(--dropdown-disabled-bg)' : 'var(--dropdown-bg)' })); },
            valueContainer: function (provided) { return (__assign(__assign({}, provided), { paddingLeft: 0, paddingRight: 0 })); },
            placeholder: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'var(--text-disabled-input)' : 'var(--text-grey)', marginLeft: 0, marginRight: 0 })); }
        } }));
}
