import React from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { ChevronDown } from 'components/accordion/ChevronDown.component';
import { ChevronUp } from 'components/accordion/ChevronUp.component';
import './accordionViewSection.css';
function Root(_a) {
    var defaultValue = _a.defaultValue, children = _a.children;
    return (React.createElement(RadixAccordion.Root, { defaultValue: defaultValue, type: "multiple", className: "tradeInAccordionViewSectionRoot" }, children));
}
function Item(_a) {
    var value = _a.value, children = _a.children;
    return (React.createElement(RadixAccordion.Item, { value: value }, children));
}
function Trigger(_a) {
    var children = _a.children;
    return (React.createElement(RadixAccordion.Header, { className: 'tradeInAccordionViewSectionHeader' },
        React.createElement(RadixAccordion.Trigger, { className: "tradeInAccordionViewSectionTrigger", "data-testid": "accordion-trigger" },
            React.createElement("div", { className: 'tradeInAccordionViewSectionTriggerWrapper' },
                React.createElement("h4", { className: 'tradeInAccordionViewSecitionTitle' }, children),
                React.createElement("div", null,
                    React.createElement(ChevronDown, { className: "chevronDown" }),
                    React.createElement(ChevronUp, { className: "chevronUp" }))))));
}
function Content(_a) {
    var children = _a.children;
    return (React.createElement(RadixAccordion.Content, { className: "tradeInAccordionViewSectionContent", "data-testid": "accordion-content" }, children));
}
export var AccordionViewSection = {
    Root: Root,
    Item: Item,
    Trigger: Trigger,
    Content: Content,
};
