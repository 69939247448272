var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { format, sub } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSpecialOfferApi } from '../useSpecialOfferApi';
import { dateNow } from '../../../utils/dateNow';
import ReactDOM from 'react-dom';
import { useStatus } from 'common/statusContext';
import { useAppError } from 'common/appErrorContext';
import { openFile } from 'common/openFile';
export function trimDoubleQuotes(string) {
    if (string[0] === '"' && string[string.length - 1] === '"') {
        return string.slice(1, string.length - 1);
    }
    return string;
}
export function useClientSection() {
    var specialOfferApi = useSpecialOfferApi();
    var _a = useState(), parties = _a[0], setParties = _a[1];
    var _b = useState(), party = _b[0], setParty = _b[1];
    var _c = useState(undefined), partyCars = _c[0], setPartyCars = _c[1];
    var _d = useState(dateNow()), requestDate = _d[0], setRequestDate = _d[1];
    var _e = useState([]), requestAttachments = _e[0], setRequestAttachments = _e[1];
    var _f = useState([]), policyAttachments = _f[0], setPolicyAttachments = _f[1];
    var _g = useState(false), isFromPartyCard = _g[0], setIsFromPartyCard = _g[1];
    var _h = useState(), partyDataFromGusOrKrs = _h[0], setPartyDataFromGusOrKrs = _h[1];
    var _j = useState(), specialOfferTypes = _j[0], setSpecialOfferTypes = _j[1];
    var _k = useState(), selectedSpecialOfferType = _k[0], setSelectedSpecialOfferType = _k[1];
    var _l = useState(null), specialOfferSubtypes = _l[0], setSpecialOfferSubtypes = _l[1];
    var _m = useState(), selectedSpecialOfferSubtype = _m[0], setSelectedSpecialOfferSubtype = _m[1];
    var setStatus = useStatus().setStatus;
    var setAppError = useAppError().setAppError;
    function getCars(partyIdentificationNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var formattedDayNow, dateFourYearsAgo, formattedFourYearsAgo, carsResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formattedDayNow = format(dateNow(), 'yyyy-MM-dd');
                        dateFourYearsAgo = sub(dateNow(), { years: 4 });
                        formattedFourYearsAgo = format(dateFourYearsAgo, 'yyyy-MM-dd');
                        return [4 /*yield*/, specialOfferApi.carsByParty(partyIdentificationNumber, formattedFourYearsAgo, formattedDayNow)];
                    case 1:
                        carsResponse = _a.sent();
                        setPartyCars(carsResponse);
                        return [2 /*return*/];
                }
            });
        });
    }
    function getBusinessEntityData(party) {
        return __awaiter(this, void 0, void 0, function () {
            var partyFromGusResponse, partyFromGusData_1, partyFromGusName_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setStatus('pending');
                        return [4 /*yield*/, specialOfferApi.getBusinessEntityData('NIP', party.identificationNumber.value)];
                    case 1:
                        partyFromGusResponse = _a.sent();
                        if (partyFromGusResponse && partyFromGusResponse.status === 200) {
                            partyFromGusData_1 = partyFromGusResponse.data;
                            partyFromGusName_1 = trimDoubleQuotes(partyFromGusData_1.name);
                            ReactDOM.unstable_batchedUpdates(function () {
                                setPartyDataFromGusOrKrs({
                                    name: partyFromGusName_1,
                                    mainPkdCode: partyFromGusData_1.mainPkdCode ? partyFromGusData_1.mainPkdCode : '',
                                    mainPkdCodeDescription: partyFromGusData_1.mainPkdCodeDescription ? partyFromGusData_1.mainPkdCodeDescription : ''
                                });
                                setStatus('success');
                            });
                        }
                        else if (partyFromGusResponse && partyFromGusResponse.status === 422) {
                            ReactDOM.unstable_batchedUpdates(function () {
                                setPartyDataFromGusOrKrs({
                                    name: '',
                                    mainPkdCode: '',
                                    mainPkdCodeDescription: ''
                                });
                                setAppError(undefined);
                                setStatus('error');
                            });
                        }
                        else {
                            ReactDOM.unstable_batchedUpdates(function () {
                                setPartyDataFromGusOrKrs({
                                    name: '',
                                    mainPkdCode: '',
                                    mainPkdCodeDescription: ''
                                });
                                setAppError(undefined);
                                setStatus('error');
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleSelectedPartyChange(value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                ReactDOM.unstable_batchedUpdates(function () {
                    var selectedParty = parties.find(function (party) { return party.id === value; });
                    setParty(selectedParty);
                    if (selectedParty.identificationNumber.type === 'NIP') {
                        getBusinessEntityData(selectedParty);
                    }
                    getCars(selectedParty.identificationNumber.value);
                });
                return [2 /*return*/];
            });
        });
    }
    function handleSpecialOfferTypeChange(value) {
        var selectedTypeObject = specialOfferTypes.find(function (type) { return type.value === value; });
        setSelectedSpecialOfferType(selectedTypeObject);
        var activeSpecialOfferSubtypes = selectedTypeObject.subTypes.filter(function (subtype) { return subtype.active; });
        var hasValueSubTypes = activeSpecialOfferSubtypes.length > 0;
        if (hasValueSubTypes) {
            setSpecialOfferSubtypes(activeSpecialOfferSubtypes);
        }
        else {
            setSpecialOfferSubtypes(null);
            setSelectedSpecialOfferSubtype(undefined);
        }
    }
    function handleSpecialOfferSubtypeChange(value) {
        var selectedSubtypeObject = specialOfferSubtypes.find(function (type) { return type.value === value; });
        setSelectedSpecialOfferSubtype(selectedSubtypeObject);
    }
    function handleChangeRequestDate(date) {
        setRequestDate(date);
    }
    function handleAddRequestAttachment(event) {
        return __awaiter(this, void 0, void 0, function () {
            var currentFile, fileData, file_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.currentTarget.files.length > 0)) return [3 /*break*/, 2];
                        currentFile = event.currentTarget.files[0];
                        return [4 /*yield*/, specialOfferApi.addFile(currentFile)];
                    case 1:
                        fileData = _a.sent();
                        file_1 = { fileUrl: fileData.fileUrl, fileName: currentFile.name };
                        setRequestAttachments(function (currentRequestAttachmentState) {
                            return __spreadArray([file_1], currentRequestAttachmentState, true);
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function handleDeleteRequestAttachment(fileUrl) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, specialOfferApi.deleteFile(fileUrl)];
                    case 1:
                        _a.sent();
                        setRequestAttachments(function (currentRequestAttachmentState) {
                            return __spreadArray([], currentRequestAttachmentState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleAddPolicAttachment(event) {
        return __awaiter(this, void 0, void 0, function () {
            var currentFile, fileData, file_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.currentTarget.files.length > 0)) return [3 /*break*/, 2];
                        currentFile = event.currentTarget.files[0];
                        return [4 /*yield*/, specialOfferApi.addFile(currentFile)];
                    case 1:
                        fileData = _a.sent();
                        file_2 = { fileUrl: fileData.fileUrl, fileName: currentFile.name };
                        setPolicyAttachments(function (currentPolicAttachmentState) {
                            return __spreadArray([file_2], currentPolicAttachmentState, true);
                        });
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function handleDeletePolicyAttachment(fileUrl) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, specialOfferApi.deleteFile(fileUrl)];
                    case 1:
                        _a.sent();
                        setPolicyAttachments(function (currentPolicyAttachmentState) {
                            return __spreadArray([], currentPolicyAttachmentState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                        });
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleFileNameClick(fileUrl, fileName) {
        specialOfferApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    useEffect(function () {
        function getParties(clientId) {
            return __awaiter(this, void 0, void 0, function () {
                var partiesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getPartiesWithIdentyficationNumber(clientId)];
                        case 1:
                            partiesResponse = _a.sent();
                            setParties(partiesResponse);
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getParty(partyId) {
            return __awaiter(this, void 0, void 0, function () {
                var partyResponse, party;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, specialOfferApi.getParty(encodeURIComponent(partyId))];
                        case 1:
                            partyResponse = _a.sent();
                            party = {
                                id: partyResponse.id,
                                type: partyResponse.type,
                                name: partyResponse.name,
                                identificationNumber: {
                                    type: partyResponse.identificationNumber.type,
                                    value: partyResponse.identificationNumber.value
                                },
                                address: __assign({}, partyResponse.address)
                            };
                            ReactDOM.unstable_batchedUpdates(function () {
                                setParty(party);
                                getCars(partyResponse.identificationNumber.value);
                                if (party.identificationNumber.type === 'NIP') {
                                    getBusinessEntityData(party);
                                }
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        function getSpecialOfferTypes() {
            return __awaiter(this, void 0, void 0, function () {
                var specialOfferTypesResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, specialOfferApi.getSpecialOfferTypes()];
                        case 1:
                            specialOfferTypesResponse = _a.sent();
                            setSpecialOfferTypes(specialOfferTypesResponse.filter(function (type) { return type.active; }));
                            return [2 /*return*/];
                    }
                });
            });
        }
        var _a = window.location.href.split('?'), searchParamsString = _a[1];
        var searchParams = new URLSearchParams(searchParamsString);
        if (searchParams.get('clientId')) {
            var clientId = searchParams.get('clientId');
            getParties(clientId);
        }
        if (searchParams.get('partyId')) {
            setIsFromPartyCard(true);
            var partyId = searchParams.get('partyId');
            getParty(partyId);
        }
        getSpecialOfferTypes();
    }, []);
    return {
        parties: parties,
        party: party,
        handleSelectedPartyChange: handleSelectedPartyChange,
        partyCars: partyCars,
        requestDate: requestDate,
        handleChangeRequestDate: handleChangeRequestDate,
        requestAttachments: requestAttachments,
        handleAddRequestAttachment: handleAddRequestAttachment,
        handleDeleteRequestAttachment: handleDeleteRequestAttachment,
        policyAttachments: policyAttachments,
        handleAddPolicAttachment: handleAddPolicAttachment,
        handleDeletePolicyAttachment: handleDeletePolicyAttachment,
        handleFileNameClick: handleFileNameClick,
        isFromPartyCard: isFromPartyCard,
        partyDataFromGusOrKrs: partyDataFromGusOrKrs,
        specialOfferTypes: specialOfferTypes,
        handleSpecialOfferTypeChange: handleSpecialOfferTypeChange,
        selectedSpecialOfferType: selectedSpecialOfferType,
        specialOfferSubtypes: specialOfferSubtypes,
        handleSpecialOfferSubtypeChange: handleSpecialOfferSubtypeChange,
        selectedSpecialOfferSubtype: selectedSpecialOfferSubtype,
        idSelectedParty: party ? party.identificationNumber.value : ''
    };
}
