import React from 'react';
import './vcpDiscountSettlement.css';
import { useVcpDiscountSettlement } from './useVcpDiscountSettlement';
import { vcpDiscountName } from 'common/vcpDiscountName';
import { TextAmount } from 'components/textAmount/TextAmount';
import { fundingName } from 'common/fundingName';
import { settlementStatus } from 'common/vcpSettlementStatus';
export function VcpDiscountSettlement(_a) {
    var orderId = _a.orderId;
    var vcpDiscountSettlement = useVcpDiscountSettlement({ orderId: orderId }).vcpDiscountSettlement;
    return (vcpDiscountSettlement &&
        React.createElement("div", { className: 'vcpDiscountSettlementWrapper', "data-testid": 'vcp-discount-settlement-wrapper' },
            React.createElement("table", { className: "vcpDiscountSettlementTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementOrderDateCell" }, "Data zam\u00F3wienia"),
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementDiscountGroupCell" }, "Grupa rabatowa VCP"),
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementCatalogPriceNetCell" }, "Cena katalogowa (netto)"),
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementClientDiscountNetCell" }, "Rabat klienta netto"),
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementVcpDiscountNetCell" }, "Rabat VCP / kwota netto"),
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementFundingCell" }, "Finansowanie"),
                        React.createElement("th", { className: "vcpDiscountSettlementHeadCell vcpDiscountSettlementStatusCell" }, "Status"))),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: 'vcpDiscountSettlementTableRow' },
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementOrderDateCell" }, vcpDiscountSettlement.orderDate),
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementDiscountGroupCell" }, vcpDiscountName(vcpDiscountSettlement.discount)),
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementCatalogPriceNetCell" },
                            React.createElement(TextAmount, { textAlignEnd: false }, vcpDiscountSettlement.listPrice)),
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementClientDiscountNetCell" },
                            React.createElement(TextAmount, { textAlignEnd: false }, vcpDiscountSettlement.clientDiscountNet)),
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementVcpDiscountNetCell" },
                            React.createElement(TextAmount, { textAlignEnd: false }, vcpDiscountSettlement.vcpDiscountNet)),
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementFundingCell" }, fundingName(vcpDiscountSettlement.funding)),
                        React.createElement("td", { className: "vcpDiscountSettlementBodyCell vcpDiscountSettlementStatusCell" }, settlementStatus(vcpDiscountSettlement.status)))))));
}
