// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .applicantSectionWrapper {
    padding: 32px;
    padding-top: 24px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    gap: 56px;
    font-family: var(--font-family);
    color: var(--main-text-color);
}

#new-dol .applicantSectionParameter { 
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-size: 14px;
    line-height: 20px;
}

#new-dol .applicantSectionLabel {
    font-weight: 500;
    margin: 0;
}

#new-dol .applicantSectionValue {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/tradeIn/applicantSection/applicantSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,SAAS;IACT,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".applicantSectionWrapper {\n    padding: 32px;\n    padding-top: 24px;\n    margin-bottom: 8px;\n    width: 100%;\n    display: flex;\n    gap: 56px;\n    font-family: var(--font-family);\n    color: var(--main-text-color);\n}\n\n.applicantSectionParameter { \n    min-width: 180px;\n    display: flex;\n    flex-direction: column;\n    gap: 14px;\n    font-size: 14px;\n    line-height: 20px;\n}\n\n.applicantSectionLabel {\n    font-weight: 500;\n    margin: 0;\n}\n\n.applicantSectionValue {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
