import React from 'react';
import ModelDictionary from 'common/modelDictionary';
import './orderingPartySection.css';
export function OrderingPartyPreviewSection(_a) {
    var orderingPartyPreviewData = _a.orderingPartyPreviewData;
    var party = orderingPartyPreviewData.party, car = orderingPartyPreviewData.car;
    return (React.createElement("div", { className: 'orderingPartySectionWrapper' },
        React.createElement("div", { className: 'orderingPartySectionDropdownsContainer' },
            React.createElement("div", { className: 'orderingPartyDropdown' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Strona klienta:"),
                party ?
                    React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-value" },
                        party.name,
                        " ",
                        party.identificationNumber.type,
                        ": ",
                        party.identificationNumber.value)
                    : null),
            React.createElement("div", { className: 'orderingPartyDropdown' },
                React.createElement("div", { className: "orderingPartyLabel" }, "Samoch\u00F3d:"),
                car ?
                    React.createElement("div", { className: "orderingPartySectionValue", "data-testid": "ordering-party-section-party-value" },
                        ModelDictionary.nameFor(car.modelId),
                        " (",
                        car.modelYear,
                        "), CON: ",
                        car.commonOrderNumber,
                        ", DO: ",
                        car.dealerOrder,
                        ", ",
                        car.vin ? car.vin + ',' : '',
                        " ",
                        car.registrationNumber ? car.registrationNumber : '')
                    : null))));
}
