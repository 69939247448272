import React from 'react';
import './drsDiscountSection.css';
import { useDrsDiscountSection } from './useDrsDiscountSection';
import { TextAmount } from 'components/textAmount/TextAmount';
import ModelDictionary from 'common/modelDictionary';
import { extraSaleDiscountStatusName } from 'common/extraSaleDiscountStatusName';
export function DrsDiscountSection(_a) {
    var identificationNumber = _a.identificationNumber, con = _a.con;
    var drsDiscountsForParty = useDrsDiscountSection(identificationNumber).drsDiscountsForParty;
    var drsDiscountForPartyForCar = drsDiscountsForParty && drsDiscountsForParty.find(function (discount) { return discount.car.commonOrderNumber === con; });
    return (drsDiscountForPartyForCar &&
        React.createElement("div", { className: 'drsDiscountWrapper', "data-testid": 'drs-discount-wrapper' },
            React.createElement("table", { className: "drsDiscountTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountDealerCell" }, "Dealer"),
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountCarCell" }, "Samoch\u00F3d"),
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountDiscountAmountNetCell" }, "Kwota rabatu netto"),
                        React.createElement("th", { className: "drsDiscountHeadCell drsDiscountStatusCell" }, "Status"))),
                React.createElement("tbody", null,
                    React.createElement("tr", { className: 'drsDiscountTableRow' },
                        React.createElement("td", { className: "drsDiscountBodyCell drsDiscountDealerCell" }, drsDiscountForPartyForCar.dealerName),
                        React.createElement("td", { className: "drsDiscountBodyCell drsDiscountCarCell" }, "".concat(ModelDictionary.nameFor(drsDiscountForPartyForCar.car.modelId.split('_')[0]), " (CON ").concat(drsDiscountForPartyForCar.car.commonOrderNumber, ")")),
                        React.createElement("td", { className: "drsDiscountBodyCell drsDiscountDiscountAmountNetCell" },
                            React.createElement(TextAmount, { textAlignEnd: false }, drsDiscountForPartyForCar.requestedNetDiscount)),
                        React.createElement("td", { className: "drsDiscountBodyCell drsDiscountStatusCell" }, extraSaleDiscountStatusName(drsDiscountForPartyForCar.status)))))));
}
