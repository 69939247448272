var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useStatus } from 'common/statusContext';
import { useEffect, useState } from 'react';
import { useTradeInApi } from '../useTradeInApi';
import ReactDOM from 'react-dom';
import { openFile } from 'common/openFile';
export function useTradeInSection() {
    var _a = useState(''), vin = _a[0], setVin = _a[1];
    var _b = useState(), firstRegistrationDate = _b[0], setFirstRegistrationDate = _b[1];
    var _c = useState(), repurchaseDate = _c[0], setRepurchaseDate = _c[1];
    var _d = useState(''), mileage = _d[0], setMileage = _d[1];
    var _e = useState(), selectSalesmen = _e[0], setSelectSalesmen = _e[1];
    var _f = useState(), selectedSelectSalesman = _f[0], setSelectedSelectSalesman = _f[1];
    var _g = useState([]), carPurchaseDocument = _g[0], setCarPurchaseDocument = _g[1];
    var _h = useState([]), registrationCertificateCopy = _h[0], setRegistrationCertificateCopy = _h[1];
    var _j = useState([]), newCarPurchaseDocument = _j[0], setNewCarPurchaseDocument = _j[1];
    var _k = useState([]), handoverProtocol = _k[0], setHandoverProtocol = _k[1];
    var _l = useState([]), consentDocument = _l[0], setConsentDocument = _l[1];
    var _m = useState([]), otherDocuments = _m[0], setOtherDocuments = _m[1];
    var setStatus = useStatus().setStatus;
    var tradeInApi = useTradeInApi();
    function handleOnVinChange(event) {
        setVin(event.currentTarget.value);
    }
    function handleChangeFirstRegistrationDate(date) {
        setFirstRegistrationDate(date);
    }
    function handleChangeRepurchaseDate(date) {
        setRepurchaseDate(date);
    }
    function handleOnMileageChange(values) {
        setMileage(values.value);
    }
    function handleSelectedSelectSalesmanChange(value) {
        ReactDOM.unstable_batchedUpdates(function () {
            var selectedSelectSalesman = selectSalesmen.find(function (salesman) { return salesman.login === value; });
            setSelectedSelectSalesman(selectedSelectSalesman);
        });
    }
    useEffect(function () {
        function getSelectSalesmen() {
            return __awaiter(this, void 0, void 0, function () {
                var selectSalesmenResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            setStatus('pending');
                            return [4 /*yield*/, tradeInApi.getSelectSalesmen()];
                        case 1:
                            selectSalesmenResponse = _a.sent();
                            ReactDOM.unstable_batchedUpdates(function () {
                                setSelectSalesmen(selectSalesmenResponse);
                                setStatus('success');
                            });
                            return [2 /*return*/];
                    }
                });
            });
        }
        getSelectSalesmen();
    }, []);
    function handleAddAttachment(event, type) {
        return __awaiter(this, void 0, void 0, function () {
            var currentFile, fileData, file_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(event.currentTarget.files.length > 0)) return [3 /*break*/, 2];
                        currentFile = event.currentTarget.files[0];
                        return [4 /*yield*/, tradeInApi.addFile(currentFile)];
                    case 1:
                        fileData = _a.sent();
                        file_1 = { fileUrl: fileData.fileUrl, fileName: currentFile.name };
                        switch (type) {
                            case 'CAR_PURCHASE':
                                setCarPurchaseDocument(function (currentCarPurchaseDocumentState) {
                                    return __spreadArray([file_1], currentCarPurchaseDocumentState, true);
                                });
                                break;
                            case 'REGISTRATION_CERTIFICATE':
                                setRegistrationCertificateCopy(function (currentRegistrationCertificateCopyState) {
                                    return __spreadArray([file_1], currentRegistrationCertificateCopyState, true);
                                });
                                break;
                            case 'NEW_CAR_PURCHASE':
                                setNewCarPurchaseDocument(function (currentNewCarPurchaseDocumentState) {
                                    return __spreadArray([file_1], currentNewCarPurchaseDocumentState, true);
                                });
                                break;
                            case 'HANDOVER_PROTOCOL':
                                setHandoverProtocol(function (currentHandoverProtocolState) {
                                    return __spreadArray([file_1], currentHandoverProtocolState, true);
                                });
                                break;
                            case 'CONSENT_DOCUMENT':
                                setConsentDocument(function (currentConsentDocumentState) {
                                    return __spreadArray([file_1], currentConsentDocumentState, true);
                                });
                                break;
                            case 'OTHER_DOCUMENT':
                                setOtherDocuments(function (currentOtherDocumentState) {
                                    return __spreadArray([file_1], currentOtherDocumentState, true);
                                });
                                break;
                            default:
                                return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    function handleDeleteAttachment(fileUrl, type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, tradeInApi.deleteFile(fileUrl)];
                    case 1:
                        _a.sent();
                        switch (type) {
                            case 'CAR_PURCHASE':
                                setCarPurchaseDocument(function (currentCarPurchaseDocumentState) {
                                    return __spreadArray([], currentCarPurchaseDocumentState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                                });
                                break;
                            case 'REGISTRATION_CERTIFICATE':
                                setRegistrationCertificateCopy(function (currentRegistrationCertificateCopyState) {
                                    return __spreadArray([], currentRegistrationCertificateCopyState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                                });
                                break;
                            case 'NEW_CAR_PURCHASE':
                                setNewCarPurchaseDocument(function (currentNewCarPurchaseDocumentState) {
                                    return __spreadArray([], currentNewCarPurchaseDocumentState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                                });
                                break;
                            case 'HANDOVER_PROTOCOL':
                                setHandoverProtocol(function (currentHandoverProtocolState) {
                                    return __spreadArray([], currentHandoverProtocolState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                                });
                                break;
                            case 'CONSENT_DOCUMENT':
                                setConsentDocument(function (currentConsentDocumentState) {
                                    return __spreadArray([], currentConsentDocumentState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                                });
                                break;
                            case 'OTHER_DOCUMENT':
                                setOtherDocuments(function (currentOtherDocumentState) {
                                    return __spreadArray([], currentOtherDocumentState.filter(function (file) { return file.fileUrl !== fileUrl; }), true);
                                });
                                break;
                            default:
                                return [2 /*return*/];
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function handleFileNameClick(fileUrl, fileName) {
        tradeInApi.loadLocation(fileUrl).then(function (response) { return openFile(response, fileName); }).catch(function () { });
        ;
    }
    var areAllObligatoryAttachments = [
        carPurchaseDocument.length,
        registrationCertificateCopy.length,
        newCarPurchaseDocument.length,
        handoverProtocol.length,
        consentDocument.length
    ].every(function (attachmentLength) { return attachmentLength !== 0; });
    var areAllDataFilled = vin !== '' &&
        firstRegistrationDate !== null &&
        repurchaseDate !== null &&
        mileage !== '' &&
        selectedSelectSalesman !== undefined;
    return {
        vin: vin,
        handleOnVinChange: handleOnVinChange,
        firstRegistrationDate: firstRegistrationDate,
        handleChangeFirstRegistrationDate: handleChangeFirstRegistrationDate,
        repurchaseDate: repurchaseDate,
        handleChangeRepurchaseDate: handleChangeRepurchaseDate,
        mileage: mileage,
        handleOnMileageChange: handleOnMileageChange,
        selectSalesmen: selectSalesmen,
        selectedSelectSalesman: selectedSelectSalesman,
        handleSelectedSelectSalesmanChange: handleSelectedSelectSalesmanChange,
        carPurchaseDocument: carPurchaseDocument,
        handleFileNameClick: handleFileNameClick,
        registrationCertificateCopy: registrationCertificateCopy,
        newCarPurchaseDocument: newCarPurchaseDocument,
        handoverProtocol: handoverProtocol,
        consentDocument: consentDocument,
        otherDocuments: otherDocuments,
        handleAddAttachment: handleAddAttachment,
        handleDeleteAttachment: handleDeleteAttachment,
        isRequiredDataFilled: areAllObligatoryAttachments && areAllDataFilled
    };
}
