import React from 'react';
import './settlementSection.css';
import { TextAmount } from 'components/textAmount/TextAmount';
import { discountStatusName } from 'pages/specialOffer/discountStatusName';
export function SettlementSection(_a) {
    var settlementData = _a.settlementData;
    return (settlementData && settlementData.length > 0 &&
        React.createElement("div", { className: 'tradeInSettlementSectionWrapper' },
            React.createElement("table", { className: "tradeInSettlementSectionTable" },
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementOrderDateCell" }, "Data zam\u00F3wienia"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementDiscountGroupCell" }, "Grupa rabatowa"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementCatalogPriceNetCell" }, "Cena katalogowa netto"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementVcpDiscountNetCell" }, "Rabat VCP / kwota netto"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementFundingCell" }, "Finansowanie"),
                        React.createElement("th", { className: "tradeInSettlementHeadCell tradeInSettlementStatusCell" }, "Status"))),
                React.createElement("tbody", null, settlementData.map(function (settlement, index) { return (React.createElement("tr", { key: index, className: 'tradeInSettlementSectionTableRow' },
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementOrderDateCell' }, settlement.orderDate),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementDiscountGroupCell' }, settlement.discountGroup),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementCatalogPriceNetCell' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.catalogPriceNet)),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementVcpDiscountNetCell' },
                        React.createElement(TextAmount, { textAlignEnd: false }, settlement.vcpDiscountNet)),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementFundingCell' }, settlement.financing),
                    React.createElement("td", { className: 'tradeInSettlementSectionBodyCell tradeInSettlementStatusCell' }, discountStatusName(settlement.status)))); })))));
}
