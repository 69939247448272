// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .newTradeInFormWrapper {
    width: 1280px;
    padding: 32px 0;
    margin: 0 auto;
}

#new-dol .newTradeInDiscountComment {
    padding: 32px 0;
}

#new-dol .tradeInSubmitButtonWrapper {
    padding: 8px 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

#new-dol .tradeInSubmitButton {
    width: 208px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tradeIn/newTradeInForm/newTradeInForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".newTradeInFormWrapper {\n    width: 1280px;\n    padding: 32px 0;\n    margin: 0 auto;\n}\n\n.newTradeInDiscountComment {\n    padding: 32px 0;\n}\n\n.tradeInSubmitButtonWrapper {\n    padding: 8px 0;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n}\n\n.tradeInSubmitButton {\n    width: 208px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
