// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .tradeInSettlementSectionWrapper {
    padding: 24px 0 40px 0;
    font-family: var(--font-family);
    color: var(--black-text-color);
}

#new-dol .tradeInSettlementSectionTable {
    width: 100%;
}

#new-dol .tradeInSettlementHeadCell {
    font-weight: 500;
    padding: 6px 8px;
}

#new-dol .tradeInSettlementSectionTableRow:nth-child(odd) {
    background-color: var(--background-very-light-grey);
}

#new-dol .tradeInSettlementSectionBodyCell {
    padding: 14px 8px;
}

#new-dol .tradeInSettlementOrderDateCell {
    padding-left: 16px;
    width: 15%;
}

#new-dol .tradeInSettlementDiscountGroupCell {
    width: 15%;
}

#new-dol .tradeInSettlementCatalogPriceNetCell {
    width: 20%;
}

#new-dol .tradeInSettlementVcpDiscountNetCell {
    width: 20%;
}

#new-dol .tradeInSettlementFundingCell {
    width: 15%;
}

#new-dol .tradeInSettlementStatusCell {
    width: 15%;
}`, "",{"version":3,"sources":["webpack://./src/pages/tradeIn/settlementSection/settlementSection.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,+BAA+B;IAC/B,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mDAAmD;AACvD;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".tradeInSettlementSectionWrapper {\n    padding: 24px 0 40px 0;\n    font-family: var(--font-family);\n    color: var(--black-text-color);\n}\n\n.tradeInSettlementSectionTable {\n    width: 100%;\n}\n\n.tradeInSettlementHeadCell {\n    font-weight: 500;\n    padding: 6px 8px;\n}\n\n.tradeInSettlementSectionTableRow:nth-child(odd) {\n    background-color: var(--background-very-light-grey);\n}\n\n.tradeInSettlementSectionBodyCell {\n    padding: 14px 8px;\n}\n\n.tradeInSettlementOrderDateCell {\n    padding-left: 16px;\n    width: 15%;\n}\n\n.tradeInSettlementDiscountGroupCell {\n    width: 15%;\n}\n\n.tradeInSettlementCatalogPriceNetCell {\n    width: 20%;\n}\n\n.tradeInSettlementVcpDiscountNetCell {\n    width: 20%;\n}\n\n.tradeInSettlementFundingCell {\n    width: 15%;\n}\n\n.tradeInSettlementStatusCell {\n    width: 15%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
