import React, { useRef } from 'react';
import './tradeInDiscountSection.css';
import { TextInput } from 'components/textInput/TextInput.component';
import { DatePickerInputWithIcon } from 'components/datepicker/DatePickerInputWithIcon';
import { Dropdown } from 'components/dropdown/Dropdown.component';
import { FileInput } from 'components/fileInput/FileInput';
import { List } from 'components/list/List.component';
import { GarbageIcon } from 'components/garbageIcon/GarbageIcon.component';
import { NumberInput } from 'components/numberInput/NumberInput.component';
export function TradeInDiscountSection(_a) {
    var tradeInDiscountSectionData = _a.tradeInDiscountSectionData;
    var vin = tradeInDiscountSectionData.vin, handleOnVinChange = tradeInDiscountSectionData.handleOnVinChange, firstRegistrationDate = tradeInDiscountSectionData.firstRegistrationDate, handleChangeFirstRegistrationDate = tradeInDiscountSectionData.handleChangeFirstRegistrationDate, repurchaseDate = tradeInDiscountSectionData.repurchaseDate, handleChangeRepurchaseDate = tradeInDiscountSectionData.handleChangeRepurchaseDate, mileage = tradeInDiscountSectionData.mileage, handleOnMileageChange = tradeInDiscountSectionData.handleOnMileageChange, selectSalesmen = tradeInDiscountSectionData.selectSalesmen, handleSelectedSelectSalesmanChange = tradeInDiscountSectionData.handleSelectedSelectSalesmanChange, carPurchaseDocument = tradeInDiscountSectionData.carPurchaseDocument, handleFileNameClick = tradeInDiscountSectionData.handleFileNameClick, registrationCertificateCopy = tradeInDiscountSectionData.registrationCertificateCopy, newCarPurchaseDocument = tradeInDiscountSectionData.newCarPurchaseDocument, handoverProtocol = tradeInDiscountSectionData.handoverProtocol, consentDocument = tradeInDiscountSectionData.consentDocument, otherDocuments = tradeInDiscountSectionData.otherDocuments, handleAddAttachment = tradeInDiscountSectionData.handleAddAttachment, handleDeleteAttachment = tradeInDiscountSectionData.handleDeleteAttachment;
    var selectSalesmenDropdownOptions = selectSalesmen
        ? selectSalesmen.map(function (selectSalesman) {
            return {
                value: selectSalesman.login,
                label: selectSalesman.name,
            };
        })
        : [];
    var inputCarPurchaseDocumentFileRef = useRef(null);
    var inputRegistrationCertificateCopyFileRef = useRef(null);
    var inputNewCarPurchaseDocumentFileRef = useRef(null);
    var inputHandoverProtocolFileRef = useRef(null);
    var inputConsentDocumentFileRef = useRef(null);
    var inputOtherDocumentsRef = useRef(null);
    return (React.createElement("div", { className: "tradeInDiscountSectionWrapper" },
        React.createElement("div", { className: "tradeInDiscountSectionGridWrapper" },
            React.createElement("div", { className: "tradeInSectionGridColumn" },
                React.createElement("div", { className: "tradeInLabelInputPair" },
                    React.createElement("div", { className: "tradeInLabel" }, "VIN samochodu odkupionego:"),
                    React.createElement("div", { className: "tradeInInputWrapper" },
                        React.createElement(TextInput, { id: "vin", name: "vin", placeholder: "Wpisz numer VIN*", value: vin, onChange: handleOnVinChange }))),
                React.createElement("div", { className: "tradeInLabelInputPair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Data pierwszej rejestracji:"),
                    React.createElement("div", { className: "tradeInInputWrapper" },
                        React.createElement(DatePickerInputWithIcon, { dateOnChangeHandler: handleChangeFirstRegistrationDate, date: firstRegistrationDate, placeholderText: "Wybierz dat\u0119*", filterWeekend: false, filterOnlySundays: true }))),
                React.createElement("div", { className: "tradeInLabelInputPair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Data odkupu samochodu:"),
                    React.createElement("div", { className: "tradeInSubLabel" }, "(z FV lub umowy kupna)"),
                    React.createElement("div", { className: "tradeInInputWrapper" },
                        React.createElement(DatePickerInputWithIcon, { date: repurchaseDate, dateOnChangeHandler: handleChangeRepurchaseDate, placeholderText: "Wybierz dat\u0119*", filterWeekend: false, filterOnlySundays: true }))),
                React.createElement("div", { className: "tradeInLabelInputPair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Przebieg (km):"),
                    React.createElement("div", { className: "tradeInInputWrapper" },
                        React.createElement(NumberInput, { id: "mileage", onValueChange: handleOnMileageChange, name: "mileage", value: mileage, valueIsNumericString: true, placeholder: 'Wpisz liczb\u0119*' }))),
                React.createElement("div", { className: "tradeInLabelInputPair" },
                    React.createElement("div", { className: "tradeInLabel" }, "Handlowiec Selekt:"),
                    React.createElement("div", { className: "tradeInInputWrapper" },
                        React.createElement(Dropdown, { name: "selectSalesman", options: selectSalesmenDropdownOptions, onChange: handleSelectedSelectSalesmanChange, placeholder: 'Wybierz*' })))),
            React.createElement("div", { className: "tradeInSectionGridColumn" },
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInLabel" }, "Dokumenty - odkup samochodu u\u017Cywanego*:"),
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "tradeInFileWrapper" },
                            React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "FV lub umowa kupna auta"),
                            React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputCarPurchaseDocumentFileRef, onFileChange: function (e) { return handleAddAttachment(e, 'CAR_PURCHASE'); }, label: "DODAJ", value: "", id: "carPurchaseAttachment" })),
                        React.createElement("div", { className: "tradeInAttachmentListWrapper" }, carPurchaseDocument.length > 0 && (React.createElement(List.Root, null, carPurchaseDocument.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                            React.createElement("div", { className: "tradeInAttachmentItem" },
                                React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                                React.createElement("button", { type: "button", className: "tradeInGarbageIconWrapper", onClick: function () { return handleDeleteAttachment(file.fileUrl, 'CAR_PURCHASE'); } },
                                    React.createElement(GarbageIcon, null))))); }))))),
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "tradeInFileWrapper" },
                            React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "Kopia dowodu rejestracyjnego"),
                            React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputRegistrationCertificateCopyFileRef, onFileChange: function (e) { return handleAddAttachment(e, 'REGISTRATION_CERTIFICATE'); }, label: "DODAJ", value: "", id: "registrationCertificateCopyAttachment" })),
                        React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, registrationCertificateCopy.length > 0 && (React.createElement(List.Root, null, registrationCertificateCopy.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                            React.createElement("div", { className: "tradeInAttachmentItem" },
                                React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                                React.createElement("button", { type: "button", className: "tradeInGarbageIconWrapper", onClick: function () { return handleDeleteAttachment(file.fileUrl, 'REGISTRATION_CERTIFICATE'); } },
                                    React.createElement(GarbageIcon, null))))); })))))),
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInLabel" }, "Dokumenty - zakup pojazdu nowego*:"),
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "tradeInFileWrapper" },
                            React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "FV sprzeda\u017Cy"),
                            React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputNewCarPurchaseDocumentFileRef, onFileChange: function (e) { return handleAddAttachment(e, 'NEW_CAR_PURCHASE'); }, label: "DODAJ", value: "", id: "newCarPurchaseAttachment" })),
                        React.createElement("div", { className: "tradeInAttachmentListWrapper" }, newCarPurchaseDocument.length > 0 && (React.createElement(List.Root, null, newCarPurchaseDocument.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                            React.createElement("div", { className: "tradeInAttachmentItem" },
                                React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                                React.createElement("button", { type: "button", className: "tradeInGarbageIconWrapper", onClick: function () { return handleDeleteAttachment(file.fileUrl, 'NEW_CAR_PURCHASE'); } },
                                    React.createElement(GarbageIcon, null))))); }))))),
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "tradeInFileWrapper" },
                            React.createElement("div", { className: "tradeInSubLabelWithoutMargin" }, "Protok\u00F3\u0142 wydania"),
                            React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputHandoverProtocolFileRef, onFileChange: function (e) { return handleAddAttachment(e, 'HANDOVER_PROTOCOL'); }, label: "DODAJ", value: "", id: "handoverProtocol" })),
                        React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, handoverProtocol.length > 0 && (React.createElement(List.Root, null, handoverProtocol.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                            React.createElement("div", { className: "tradeInAttachmentItem" },
                                React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                                React.createElement("button", { type: "button", className: "tradeInGarbageIconWrapper", onClick: function () { return handleDeleteAttachment(file.fileUrl, 'HANDOVER_PROTOCOL'); } },
                                    React.createElement(GarbageIcon, null))))); })))))),
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInSectionFileWrapper" },
                        React.createElement("div", { className: "tradeInLabelWithoutMargin" }, "Dokument zgody*:"),
                        React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputConsentDocumentFileRef, onFileChange: function (e) { return handleAddAttachment(e, 'CONSENT_DOCUMENT'); }, label: "DODAJ", value: "", id: "consentDocument" })),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, consentDocument.length > 0 && (React.createElement(List.Root, null, consentDocument.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                            React.createElement("button", { type: "button", className: "tradeInGarbageIconWrapper", onClick: function () { return handleDeleteAttachment(file.fileUrl, 'CONSENT_DOCUMENT'); } },
                                React.createElement(GarbageIcon, null))))); }))))),
                React.createElement("div", { className: "tradeInDocumentSectionWrapper" },
                    React.createElement("div", { className: "tradeInSectionFileWrapper" },
                        React.createElement("div", { className: "tradeInLabelWithoutMargin" }, "Inne dokumenty:"),
                        React.createElement(FileInput, { acceptFile: ".pdf", fileRef: inputOtherDocumentsRef, onFileChange: function (e) { return handleAddAttachment(e, 'OTHER_DOCUMENT'); }, label: "DODAJ", value: "", id: "otherDocuments" })),
                    React.createElement("div", { className: "tradeInAttachmentListWrapper last" }, otherDocuments.length > 0 && (React.createElement(List.Root, null, otherDocuments.map(function (file) { return (React.createElement(List.Item, { key: file.fileUrl },
                        React.createElement("div", { className: "tradeInAttachmentItem" },
                            React.createElement("div", { onClick: function () { return handleFileNameClick(file.fileUrl, file.fileName); } }, file.fileName),
                            React.createElement("button", { type: "button", className: "tradeInGarbageIconWrapper", onClick: function () { return handleDeleteAttachment(file.fileUrl, 'OTHER_DOCUMENT'); } },
                                React.createElement(GarbageIcon, null))))); })))))))));
}
