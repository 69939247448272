// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .attachmentsInput {
    visibility: hidden;
    position: absolute;
    top: 0; 
    left: 0; 
    width: 0; 
    height: 0
}

#new-dol .attachmentButton {
    border: 0;
    background-color: unset;
    color: var(--blue);
    width: auto;
    height: 20px;
    padding: 0 10px;
    font-weight: 500;
}

#new-dol .attachmentsButtonDisabled {
    opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/components/fileInput/fileInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR;AACJ;;AAEA;IACI,SAAS;IACT,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".attachmentsInput {\n    visibility: hidden;\n    position: absolute;\n    top: 0; \n    left: 0; \n    width: 0; \n    height: 0\n}\n\n.attachmentButton {\n    border: 0;\n    background-color: unset;\n    color: var(--blue);\n    width: auto;\n    height: 20px;\n    padding: 0 10px;\n    font-weight: 500;\n}\n\n.attachmentsButtonDisabled {\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
