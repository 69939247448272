import React from 'react';
import './applicantSection.css';
import { formatDate } from 'utils/formatDate';
export function ApplicantSection(_a) {
    var applicantSectionData = _a.applicantSectionData;
    return (applicantSectionData &&
        React.createElement("div", { className: 'applicantSectionWrapper' },
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Data wniosku:"),
                React.createElement("p", { className: 'applicantSectionValue' }, formatDate(applicantSectionData.applicationDate))),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Nazwa dealera:"),
                React.createElement("p", { className: 'applicantSectionValue' }, applicantSectionData.organizationName)),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Opiekun Strony:"),
                React.createElement("p", { className: 'applicantSectionValue' }, applicantSectionData.assistantName)),
            React.createElement("div", { className: 'applicantSectionParameter' },
                React.createElement("p", { className: 'applicantSectionLabel' }, "Wnioskuj\u0105cy:"),
                React.createElement("p", { className: 'applicantSectionValue' }, applicantSectionData.applicantName)),
            applicantSectionData && applicantSectionData.applicationId ?
                React.createElement("div", { className: 'applicantSectionParameter' },
                    React.createElement("p", { className: 'applicantSectionLabel' }, "Numer wniosku:"),
                    React.createElement("p", { className: 'applicantSectionValue' }, applicantSectionData.applicationId))
                : null));
}
