// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .additionalInfosSectionWrapper {
    padding: 16px;
    width: 100%;
    font-family: var(--font-family);
    color: var(--main-text-color);
}

#new-dol .additionalInfoTextArea {
    width: 936px;
    height: 96px;
}`, "",{"version":3,"sources":["webpack://./src/pages/tradeIn/additionalInfosSection/additionalInfosSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".additionalInfosSectionWrapper {\n    padding: 16px;\n    width: 100%;\n    font-family: var(--font-family);\n    color: var(--main-text-color);\n}\n\n.additionalInfoTextArea {\n    width: 936px;\n    height: 96px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
