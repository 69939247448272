var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import './newTradeInForm.css';
import { ViewSection } from '../viewSection/ViewSection';
import { ApplicantSection } from '../applicantSection/ApplicantSection';
import { OrderingPartySection } from '../orderingPartySection/OrderingPartySection';
import { TradeInDiscountSection } from '../tradeInDiscountSection/TradeInDiscountSection';
import { AdditionalInfosSection } from '../additionalInfosSection/AdditionalInfosSection';
import { useApplicantSection } from '../applicantSection/useApplicantSection';
import { withCancellation } from 'common/requestCancellation/requestCancellation';
import { useOrderingPartySection } from '../orderingPartySection/useOrderingPartySection';
import { useTradeInSection } from '../tradeInDiscountSection/useTradeInDiscountSection';
import { useAdditionalInfosSection } from '../additionalInfosSection/useAdditionalInfosSection';
import { HorizontalLine } from 'pages/specialOffer/horizontalLine/HorizontalLine';
import { Button } from 'components/button/Button.component';
import { formatShortDate } from 'utils/formatShortDate';
import { useStatus } from 'common/statusContext';
import { useTradeInApi } from '../useTradeInApi';
import { InfoDialog } from 'pages/specialOffer/InfoDialog';
import { Spinner } from 'components/spinner/Spinner';
export var NewTradeInForm = withCancellation(function () {
    var _a = useStatus(), status = _a.status, setStatus = _a.setStatus;
    var _b = useState(false), openConfirmDialog = _b[0], setOpenConfirmDialog = _b[1];
    var _c = useState(''), dialogMessage = _c[0], setDialogMessage = _c[1];
    var applicantSectionData = useApplicantSection({ mode: 'CREATE' });
    var orderingPartySectionData = useOrderingPartySection();
    var tradeInDiscountSectionData = useTradeInSection();
    var additionalInfosSectionData = useAdditionalInfosSection();
    var tradeInApi = useTradeInApi();
    var _d = window.location.href.split('?'), searchParamsString = _d[1];
    var searchParams = new URLSearchParams(searchParamsString);
    function onSaveTradeInClick() {
        return __awaiter(this, void 0, void 0, function () {
            var tradeInData, saveNewSpecialOfferResponse, clientId, partyId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tradeInData = {
                            applicant: {
                                applicationDate: formatShortDate(applicantSectionData.applicationDate),
                                organizationId: applicantSectionData.organizationId,
                                assistantId: applicantSectionData.assistantId,
                                applicantId: applicantSectionData.applicantId
                            },
                            ordering: {
                                party: orderingPartySectionData.party,
                                car: orderingPartySectionData.selectedCar
                            },
                            tradeIn: {
                                vin: tradeInDiscountSectionData.vin,
                                firstRegistrationDate: formatShortDate(tradeInDiscountSectionData.firstRegistrationDate),
                                repurchaseDate: formatShortDate(tradeInDiscountSectionData.repurchaseDate),
                                mileage: tradeInDiscountSectionData.mileage,
                                selectSalesman: tradeInDiscountSectionData.selectedSelectSalesman,
                                carPurchaseDocument: tradeInDiscountSectionData.carPurchaseDocument,
                                registrationCertificateCopy: tradeInDiscountSectionData.registrationCertificateCopy,
                                newCarPurchaseDocument: tradeInDiscountSectionData.newCarPurchaseDocument,
                                handoverProtocol: tradeInDiscountSectionData.handoverProtocol,
                                consentDocument: tradeInDiscountSectionData.consentDocument,
                                otherDocuments: tradeInDiscountSectionData.otherDocuments
                            },
                            additionalInfo: additionalInfosSectionData.additionalInfo
                        };
                        // TODO: w zadaniu z integracją z BE
                        setStatus('pending');
                        return [4 /*yield*/, tradeInApi.saveTradeId(tradeInData)];
                    case 1:
                        saveNewSpecialOfferResponse = _a.sent();
                        // if (saveNewSpecialOfferResponse && saveNewSpecialOfferResponse.data) {
                        setStatus('success');
                        if (searchParams.has('clientId')) {
                            clientId = searchParams.get('clientId');
                            window.location.href = "#/customer-service/".concat(clientId, "/");
                        }
                        if (searchParams.has('partyId')) {
                            partyId = searchParams.get('partyId');
                            window.location.href = "#/fleet-service/".concat(encodeURIComponent(partyId));
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function isSaveDisabled() {
        return !orderingPartySectionData.isRequiredDataFilled || !tradeInDiscountSectionData.isRequiredDataFilled;
    }
    function handleOnConfirmDialog() {
        setOpenConfirmDialog(false);
        setDialogMessage('');
    }
    return (React.createElement("div", { className: 'newTradeInFormWrapper' },
        React.createElement(ViewSection, { title: 'wnioskuj\u0105cy', dataTestid: "trade-in-applicant-section" },
            React.createElement(ApplicantSection, { applicantSectionData: applicantSectionData })),
        React.createElement(ViewSection, { title: 'zamawiaj\u0105cy', dataTestid: "trade-in-client-section" },
            React.createElement(OrderingPartySection, { orderingPartySectionData: orderingPartySectionData })),
        React.createElement(ViewSection, { title: 'rabat trade-in', dataTestid: "trade-in-discount-section" },
            React.createElement(TradeInDiscountSection, { tradeInDiscountSectionData: tradeInDiscountSectionData })),
        React.createElement(ViewSection, { title: 'informacje dodatkowe', dataTestid: "trade-in-additional-infos" },
            React.createElement("div", { className: "newTradeInDiscountComment" },
                React.createElement(AdditionalInfosSection, { additionalInfosSectionData: additionalInfosSectionData }))),
        React.createElement(HorizontalLine, { color: "#6D6D6D" }),
        React.createElement("div", { className: "tradeInSubmitButtonWrapper" },
            React.createElement("div", { className: "tradeInSubmitButton" },
                React.createElement(Button, { variant: "primary", type: "button", onClick: onSaveTradeInClick, disabled: isSaveDisabled() }, "wy\u015Blij zapytanie"))),
        React.createElement(InfoDialog, { open: openConfirmDialog, message: dialogMessage, onConfirm: handleOnConfirmDialog }),
        status === 'pending' ? React.createElement(Spinner, null) : null));
});
