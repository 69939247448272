// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/webpack-config-single-spa-react/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#new-dol .newOfferFromStockContainer {
    padding: 32px 44px;
    font-family: var(--font-family);
}

#new-dol .newOfferFromStockContainer p {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/newOfferFromStock/newOfferFromStock.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,SAAS;AACb","sourcesContent":[".newOfferFromStockContainer {\n    padding: 32px 44px;\n    font-family: var(--font-family);\n}\n\n.newOfferFromStockContainer p {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
